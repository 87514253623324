import React from 'react'
import { useCheckout2 } from 'setup/CheckoutProviderFields'
import { AreaContentRow, AreaHeading, Area, LayoutArea } from '../styledComponents'
import { contactSchemaAnon } from '../util/validationSchema'
import { Form, Formik } from 'formik'
import Required from 'pageComponents/_common/required'
import Input from 'pageComponents/_common/formik/input_v2'
import { CheckCircle } from '@mui/icons-material'

export default function ContactInfoAnon() {
    const {
        checkoutEditableFields,
        setCheckoutEditableFields,
        validationStatus,
        inPageRefs
    } = useCheckout2();

    const handleValidateFields = values => {
        contactSchemaAnon
            .isValid(values).then((valid) => {
                if(valid) { 
                    setCheckoutEditableFields({...checkoutEditableFields, SelectedContact_Email: values.SelectedContact_Email }
                        )
                    }
                });
    }

    return (
        <Area>
            <AreaHeading ref={inPageRefs.contactSectionRef}>
                <h1>Contact</h1>
                <CheckCircle titleAccess={validationStatus.IsContactValid ? 'Contact section is valid' : 'Contact section is not valid'} label='Contact' htmlColor={validationStatus.IsContactValid ? 'green' : '#CDC'}/>
            </AreaHeading>
            <LayoutArea>
                <AreaContentRow>
                    <Formik
                        initialValues={checkoutEditableFields}
                        enableReinitialize={false}
                        validationSchema={contactSchemaAnon}
                        validate={handleValidateFields}
                        validateOnBlur={true}
                        validateOnChange={true}
                    >
                        <Form>
                            <Input type={'email'}
                                label={<>Email<Required /></>}
                                name="SelectedContact_Email"
                            />
                        </Form>
                    </Formik>
                </AreaContentRow>
            </LayoutArea>
        </Area>
    )
}