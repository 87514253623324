import React from 'react'
import styled from 'styled-components'


const BrandsDiv = styled.div`
    display: flex;
    flex-direction: column;
    width: 968px;
    flex-wrap: wrap;
    background-color: white;
    padding: 30px 0 20px;
    border: 1px solid lightgray;
    border-top: 0;
`
const BrandslogoDiv = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 25px;
`
const LogoDiv = styled.img`
    width: 100%;
    max-width: 100px
`
const AllBrandsButton = styled.button`
    border: 0;
    padding: 5px 15px;
    border-radius: 6px;
    background-color: #fff;
    text-decoration: underline;
    font-size: 16px;
`

const A = styled.a`
`
const Div = styled.div`
    display: flex;
`
const Row = styled.div`
    display: flex;
    flex-direction: column;
    display: inline-flex;
    background-color: #f2f3f4;
    padding: 15px 10px;
    text-align: center;
`
const Container = styled.div`
    display: flex;
    box-shadow: 0px 3px 4px #dadada;
`
const H5 = styled.h5`
`
const P = styled.p`
`
const ImgDiv = styled.div`
    text-align: center;
`
const Img = styled.img`
    max-width: 180px;
`
const A2 = styled.a`
    color: #000000;
    &:hover{
        text-decoration: none;
    }
`
export default function BrandsDropdownMenu() {
  
    return (
    <Container>
        <BrandsDiv>
            <BrandslogoDiv>
                <A href="/brands/featured/abb"><LogoDiv src="https://airlinemedia.airlinehyd.com/Static_pages/Brands/Brand-logos/ABB_logo.png" /></A>
                <A href="/brands/featured/banner"><LogoDiv src="https://airlinemedia.airlinehyd.com/Static_pages/Brands/Brand-logos/Banner-Engineering.png" /></A>
                <A href="/brands/featured/butech"><LogoDiv src="https://airlinemedia.airlinehyd.com/Static_pages/Brands/Brand-logos/BuTech_Logo.png" /></A>
                <A href="/brands/featured/clippard"><LogoDiv src="https://airlinemedia.airlinehyd.com/Static_pages/Brands/Brand-logos/Clippard.png" /></A>
                <A href="/brands/featured/eaton"><LogoDiv src="https://airlinemedia.airlinehyd.com/Static_pages/Brands/Brand-logos/Eaton_logo.png" /></A>
            </BrandslogoDiv>
            <BrandslogoDiv>
                <A href="/brands/featured/haskel"><LogoDiv src="https://airlinemedia.airlinehyd.com/Static_pages/Brands/Brand-logos/Haskel.png" /></A>
                <A href="/brands/featured/hydac"><LogoDiv src="https://airlinemedia.airlinehyd.com/Static_pages/Brands/Brand-logos/hydac.png" /></A>
                <A href="/pages/brands/icotek"><LogoDiv src="https://airlinemedia.airlinehyd.com/Static_pages/Brands/icotek/icotek_logo1.png" /></A>
                <A href="/brands/featured/lincoln"><LogoDiv src="https://airlinemedia.airlinehyd.com/Static_pages/Brands/Brand-logos/Lincoln_SKF_condensed%20RGB%20nobkgrd.jpg" /></A>
                <A href="/brands/featured/omron"><LogoDiv src="https://airlinemedia.airlinehyd.com/Static_pages/Brands/Brand-logos/OMRON_logo.png" /></A>
            </BrandslogoDiv>
            <BrandslogoDiv>
                <A href="/brands/featured/oriental-motor"><LogoDiv src="https://airlinemedia.airlinehyd.com/Static_pages/Brands/Brand-logos/oriental-motor.png" /></A>
                <A href="/brands/featured/paccar"><LogoDiv src="https://airlinemedia.airlinehyd.com/Static_pages/Brands/Brand-logos/paccar-logo.png" /></A>
                <A href="/brands/featured/parker"><LogoDiv src="https://airlinemedia.airlinehyd.com/Static_pages/Brands/Brand-logos/parker_logo.jpg" /></A>
                <A href="/brands/featured/phoenix-contact"><LogoDiv src="https://airlinemedia.airlinehyd.com/Static_pages/Brands/Brand-logos/phoenix-contact-logo.png" /></A>
                <A href="/brands/featured/rexroth"><LogoDiv src="https://airlinemedia.airlinehyd.com/Static_pages/Brands/Brand-logos/Rexroth-Logo_RGB.png" /></A>
            </BrandslogoDiv>
            <BrandslogoDiv>
                <A href="/brands/featured/rittal"><LogoDiv src="https://airlinemedia.airlinehyd.com/Static_pages/Brands/Brand-logos/RITTAL.png" /></A>
                <A href="/brands/featured/ross"><LogoDiv src="https://airlinemedia.airlinehyd.com/Static_pages/Brands/Brand-logos/ROSS_LOGO.png" /></A>
                <A href="/brands/featured/smc"><LogoDiv src="https://airlinemedia.airlinehyd.com/Static_pages/Brands/Brand-logos/SMC%20Elite%20Dist%20web.png" /></A>
                <A href="/brands/featured/turck"><LogoDiv src="https://airlinemedia.airlinehyd.com/Static_pages/Brands/Brand-logos/Turck.png" /></A>
                <A href="/brands/featured/yates"><LogoDiv src="https://airlinemedia.airlinehyd.com/Static_pages/Brands/Brand-logos/yates.webp" /></A>
            </BrandslogoDiv>
            <BrandslogoDiv>
                 <A href="/pages/brands"><AllBrandsButton>All Brands</AllBrandsButton></A>
            </BrandslogoDiv>
        </BrandsDiv>
        <Div>
            <Row>
                <A2 href="https://airlinemedia.airlinehyd.com/Static_pages/resources/Airline%20Hydraulics%20Premier%20Products%20-%20Line%20Card.pdf" target="_blank">
                <H5>Download our Line Card</H5>
                <P>View all of our product lines and services in one convenient place</P>
                <ImgDiv>
                    <Img src="https://airlinemedia.airlinehyd.com/Static_pages/resources/LineCard.png" />
                </ImgDiv>
                </A2>
            </Row>
        </Div>
    </Container>
    )
}