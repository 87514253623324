import React, { useEffect, useState } from 'react'
import AddressBox from './AddressBox'
import AddressInput from './AddressInput'
import { FlexCol, FlexRow, InlineButtonRed } from '../../styledComponents'
import { useCheckout2 } from 'setup/CheckoutProviderFields'
import { addressSchema } from 'pageComponents/Checkout2/util/validationSchema'

export default function AddressViewEdit(props) {
    const {
        address,
        addressUpdatedCallback,
        addressType,
        showAddressEditor,
        setShowAddressEditor
    } = props;

    const {  
        checkoutEditableFields, 
        setCheckoutEditableFields,
        verifyShipToAddress
    } = useCheckout2();

    useEffect(() => {
        //If the address is valid, hide the editor
        addressSchema.validate(address).catch(e => setShowAddressEditor(true));
    }, []);

    function handleEdit() {
        setShowAddressEditor(true);
    }

    function updateAddress(addr) {
        console.log('addressView.updateAddress', addr);
            
        //Let the parent know the address has changed
        //(updates the local state for display)
        addressUpdatedCallback(addr);
        //Update the checkout2 state for sync to DB
        if(addressType === 'billing') {
            setCheckoutEditableFields({
                ...checkoutEditableFields,
                BillingAddress_P21Id: addr.P21Id || 0,
                BillingAddress_Name: addr.Name,
                BillingAddress_FirstName: addr.FirstName,
                BillingAddress_LastName: addr.LastName,
                BillingAddress_Address1: addr.Address1,
                BillingAddress_Address2: addr.Address2,
                BillingAddress_City: addr.City,
                BillingAddress_State: addr.State,
                BillingAddress_Zip: addr.Zip,
                BillingAddress_Country: addr.Country || 'US',
                BillingAddress_Phone: addr.Phone,
                BillingAddress_PhoneExt: addr.PhoneExt,
            });
        } else if(addressType === 'shipping') {
            //Dont call save here directly, call validate/verify which will
            // cascade the update to checkoutEditableFields
            verifyShipToAddress({
                P21Id: addr.P21Id || 0,
                Name: addr.Name,
                FirstName: addr.FirstName,
                LastName: addr.LastName,
                Address1: addr.Address1,
                Address2: addr.Address2,
                City: addr.City,
                State: addr.State,
                Zip: addr.Zip,
                Country: addr.Country || 'US',
                Phone: addr.Phone || '',
                PhoneExt: addr.PhoneExt || '',
                DeliveryInstructions: addr.DeliveryInstructions,
                PreferredPackingBasis: addr.PreferredPackingBasis,
                PreferredCarrierName: addr.PreferredCarrierName,
                IsCollect: addr.IsCollect,
                CollectAccount: addr.CollectAccount,
            });
        }
    }

    return (
        <FlexCol>
            <FlexRow>
                {showAddressEditor ? (
                    <AddressInput 
                        address={address}
                        addressType={addressType}
                        hide={() => setShowAddressEditor(false)}
                        addressUpdatedCallback={updateAddress}
                    />
                ) : (
                    <>
                        <AddressBox address={address} hideExtra/>
                        <InlineButtonRed onClick={() => handleEdit()}>Edit...</InlineButtonRed>
                    </>
                )}
            </FlexRow>
        </FlexCol>
    )
}