import React, { useContext, useEffect, useState } from 'react'
import { ButtonBlack } from 'styles/buttons'
import {
    AreaContentRow, AreaHeading, Bold, HorizontalPack, Area, Textarea, FlexInline,
    FullWidth, EyeSpace, FlexChild, LayoutArea, StyledCheckbox, HeaderActionButton
} from '../styledComponents'
import DocumentLinkModal from 'pageComponents/ShoppingCart/uiComponents/DocumentLinkModal'
import Email from '../components/Email'
import Eyecon from 'pageComponents/_common/Eyecon'
import MyContext from 'setup/context'
import { useCheckout2 } from 'setup/CheckoutProviderFields'
import { CheckCircle } from '@mui/icons-material'
import Required from 'pageComponents/_common/required'
import AddressViewEdit from '../components/address/AddressViewEdit'
import AddressBox from '../components/address/AddressBox'
import { ErrorMessage, Form, Formik } from 'formik'
import { FormikFormContainer, FormikFormFieldError, FormikFormGroup } from 'styles/formikForm'
import PasswordRequirements from 'pageComponents/PasswordReset/uiComponents/passwordRequirements'
import FormikInput from '../../_common/formik/input_v2'
import { justPasswordValidateSchema } from 'pageComponents/Signup/validationSchemas'
import Loader from 'pageComponents/_common/loader'
import { Link } from 'react-router-dom'
import { ErrorAlert, InfoAlert } from 'styles/alerts'

export default function OtherInfo(props) {
    const { userInfo } = useContext(MyContext);
    const {
        checkoutEditableFields, 
        setCheckoutEditableFields, 
        setCheckoutEditableField,
        validationStatus,
        addPassword,
        inPageRefs,
        setOpenEditorField,
		openEditors
    } = useCheckout2();

    const [showAttach, setShowAttach] = useState(false);
    const [showPaperwork, setShowPaperwork] = useState(false);
    const [showAddressEditor, _setShowAddressEditor] = useState(!userInfo);
    function setShowAddressEditor(show) {
        //Implements a tracker for the open editor so checkout can not proceed if the editor is open
        setOpenEditorField('billingAddress', show);
        _setShowAddressEditor(show);
    } 

    //Monitor the checkbox for Billing same as Shipping. If it is checked, then the address editor is not open
    useEffect(() => {
        if(checkoutEditableFields.IsBillingSameAsShipping) {
            setShowAddressEditor(false);
        }
    }, [checkoutEditableFields])

    //Monitor the validation state of the NewAccount section, to let CheckoutProviderFields track the password editor  
    useEffect(() => {
        setOpenEditorField('newAccount', !(validationStatus.NewAccountStatus.Status === "Ok" || validationStatus.NewAccountStatus.Status === "Disabled"));
    }, [validationStatus])

    const billingAddress = {
        Name: checkoutEditableFields.BillingAddress_Name,
        FirstName: checkoutEditableFields.BillingAddress_FirstName,
        LastName: checkoutEditableFields.BillingAddress_LastName,
        Address1: checkoutEditableFields.BillingAddress_Address1,
        Address2: checkoutEditableFields.BillingAddress_Address2,
        City: checkoutEditableFields.BillingAddress_City,
        State: checkoutEditableFields.BillingAddress_State,
        Zip: checkoutEditableFields.BillingAddress_Zip,
        Country: checkoutEditableFields.BillingAddress_Country,
        Phone: checkoutEditableFields.BillingAddress_Phone,
        PhoneExt: checkoutEditableFields.BillingAddress_PhoneExt,
    };
    const [currentEditingAddress, setCurrentEditingAddress] = useState(billingAddress);

    const shippingAddress = {
        Name: checkoutEditableFields.ShippingChoices_Name,
        FirstName: checkoutEditableFields.ShippingChoices_FirstName,
        LastName: checkoutEditableFields.ShippingChoices_LastName,
        Address1: checkoutEditableFields.ShippingChoices_Address1,
        Address2: checkoutEditableFields.ShippingChoices_Address2,
        City: checkoutEditableFields.ShippingChoices_City,
        State: checkoutEditableFields.ShippingChoices_State,
        Zip: checkoutEditableFields.ShippingChoices_Zip,
        Country: checkoutEditableFields.ShippingChoices_Country,
        Phone: checkoutEditableFields.ShippingChoices_Phone,
        PhoneExt: checkoutEditableFields.ShippingChoices_PhoneExt,
    };

    const [newAcctPassword] = useState({ password: "", verifyPassword: "", passwordStrength: false});

    //Called by formik, passes value down to CheckoutProviderFields to add the password to the Checkout record
    function savePassword(passwordFormValues) {
        addPassword(passwordFormValues.password);
    }
	const billToValid = validationStatus.IsBillToValid && !openEditors.billing && !openEditors.billingAddress;

    return (
        <Area>
            <AreaHeading ref={inPageRefs.billingAndOtherSectionRef}>
                <h1>Billing &amp; Other</h1>
                <CheckCircle titleAccess={billToValid ? 'Bill To section is valid' : 'Bill To section is not valid'} label='Bill to is Valid' htmlColor={billToValid ? 'green' : '#CDC'}/>
            </AreaHeading>
            <LayoutArea>
                <AreaContentRow>
                    <FlexChild>
                        <label htmlFor='IsBillingSameAsShipping'>Billing address same as shipping</label>
                        <StyledCheckbox
                            id='IsBillingSameAsShipping'
                            name='IsBillingSameAsShipping'
                            type="checkbox"
                            checked={checkoutEditableFields.IsBillingSameAsShipping}
                            onChange={e => setCheckoutEditableField('IsBillingSameAsShipping', e.target.checked)}
                        />
                    </FlexChild>
                </AreaContentRow>
                <AreaContentRow>
                    {!checkoutEditableFields.IsBillingSameAsShipping && 
                        <AddressViewEdit 
                            address={currentEditingAddress}
                            addressUpdatedCallback={(addr) => setCurrentEditingAddress(addr)}
                            addressType="billing"
                            showAddressEditor={showAddressEditor}
                            setShowAddressEditor={setShowAddressEditor}
                        />
                    }
                    {!!checkoutEditableFields.IsBillingSameAsShipping &&
                        <AddressBox address={shippingAddress} />
                    }
                </AreaContentRow>
                <AreaContentRow>
                    <Bold>Order Notes</Bold>
                </AreaContentRow>
                <AreaContentRow>
                    <FullWidth>
                        <FlexInline>
                            {userInfo?.isImpersonatorUser ? <Eyecon {...{ userInfo }} /> : <EyeSpace />}
                            <Textarea
                                placeholder="(optional)"
                                value={checkoutEditableFields.Notes}
                                rows={3}
                                onChange={(e) => setCheckoutEditableFields({ ...checkoutEditableFields, Notes: e.target.value })}
                            />
                        </FlexInline>
                    </FullWidth>
                </AreaContentRow>
                {userInfo?.isImpersonatorUser && (<>
                    <AreaContentRow>
                        <Bold>Confirmation Email</Bold>
                    </AreaContentRow>
                    <AreaContentRow>
                        <Email />
                    </AreaContentRow></>
                )}
                {!userInfo && (<>
                    <AreaContentRow>
                        <FullWidth>
                            <FlexChild>
                                <label htmlFor="IsCreateAccount">Create an account for next time?</label>
                                <StyledCheckbox 
                                    id="IsCreateAccount"
                                    name="IsCreateAccount" 
                                    type="checkbox" 
                                    checked={checkoutEditableFields.IsCreateAccount} 
                                    onChange={(e) => setCheckoutEditableFields({ ...checkoutEditableFields, IsCreateAccount: e.target.checked }) } 
                                />
                            </FlexChild>
                        </FullWidth>
                    </AreaContentRow>
                    {/* Create account - Require password */}
                    {!!checkoutEditableFields.IsCreateAccount && <>
                        {validationStatus.NewAccountStatus.Status === "DuplicateEmail" &&
                            <AreaContentRow>
                                <FullWidth>
                                    <FlexChild>
                                        <ErrorAlert ref={inPageRefs.newAccountSectionRef} style={{textAlign: 'center'}}>
                                            The email you're using to checkout is already registered. <Link to="/login">Log in?</Link>
                                        </ErrorAlert>
                                    </FlexChild>
                                </FullWidth>
                            </AreaContentRow>
                        }
                        {validationStatus.NewAccountStatus.Status === "InvalidEmail" &&
                            <AreaContentRow>
                                <FullWidth>
                                    <FlexChild>
                                        <ErrorAlert ref={inPageRefs.newAccountSectionRef} style={{ textAlign: 'center'}}>
                                            The email you're using to checkout is not valid.
                                        </ErrorAlert>
                                    </FlexChild>
                                </FullWidth>
                            </AreaContentRow>
                        }
                        {validationStatus.NewAccountStatus.Status === "NeedsPassword" &&
                            <AreaContentRow>
                                <FullWidth>
                                    <FlexChild>
                                        <span ref={inPageRefs.newAccountSectionRef} style={{ fontStyle: 'italic', textAlign: 'center'}}>
                                            <Required/> Set a password below, and then click Save Password
                                        </span>
                                    </FlexChild>
                                </FullWidth>
                            </AreaContentRow>
                        }
                        {validationStatus.NewAccountStatus.Status === "Ok" &&
                            <AreaContentRow>
                                <FullWidth>
                                    <FlexChild>
                                        <InfoAlert ref={inPageRefs.newAccountSectionRef} style={{textAlign: 'center'}}>
                                            <CheckCircle titleAccess='Your Password Is Saved' label='Password Saved' htmlColor='green' />
                                                We will create a new account for you when you check out, using your saved password.
                                        </InfoAlert>
                                    </FlexChild>
                                </FullWidth>
                            </AreaContentRow>
                        }
                        {validationStatus.NewAccountStatus.Status !== "Ok" && 
                            <Formik
                                initialValues={newAcctPassword}
                                enableReinitialize={false}
                                validateOnBlur={true}
                                validateOnChange={true}
                                validateOnMount={true}
                                validationSchema={justPasswordValidateSchema}
                                onSubmit={(values, actions) =>{ savePassword(values); actions.setSubmitting(false); }}
                            >
                                {({props, values, setFieldValue, isSubmitting, isValid}) => (<Form>
                                    <FormikFormContainer>
                                        <FormikFormGroup>
                                            <h4>Create a new account</h4>
                                            <FormikInput label={<>Password<Required /></>} type="password" name="password"  />
                                            <FormikInput label={<>Confirm Password<Required /></>} type="password" name="verifyPassword" />
                                            <PasswordRequirements
                                                password={values.password}
                                                confirmPassword={values.verifyPassword}
                                                isValidPassword={(v) => {console.log('isvalid', v); setFieldValue('passwordStrength', v);}}
                                            />
                                            <FormikFormFieldError style={{ width: '400px' }}>
                                                <ErrorMessage name="passwordStrength" />
                                            </FormikFormFieldError>
                                            {isSubmitting && <Loader />}
                                            <HeaderActionButton type="submit" name="submit" disabled={!isValid}>Save Password</HeaderActionButton>
                                        </FormikFormGroup>
                                    </FormikFormContainer>
                                </Form>)}
                            </Formik>
                        }
                    </>}
                </>)}
                {userInfo && (<>
                    <AreaContentRow>
                        <Bold>Attachments &amp; Documentation</Bold>
                    </AreaContentRow>
                    <AreaContentRow>
                        {!showPaperwork && (
                            <HorizontalPack>
                                <EyeSpace />
                                <ButtonBlack onClick={() => setShowAttach(true)}>Attach Files</ButtonBlack>
                            </HorizontalPack>
                        )}
                    </AreaContentRow>
                    {showAttach && (
                        <DocumentLinkModal
                            {...{
                                open: showAttach,
                                hide: () => setShowAttach(false),
                                accept: 'image/jpeg, application/pdf, image/png',
                                allowedMessage: 'Maximum 5 files, 10MB size. Only jpg, png or pdf allowed.',
                                maxFiles: 5,
                            }}
                        />
                    )}
                </>)}
            </LayoutArea>
        </Area >
    )
}
