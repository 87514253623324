import React, { useEffect, useState } from 'react'
import ContactBox from './ContactBox'
import { HeaderActionButton, Layout, SelectedBorder, ScrollContainer, FlexChild, Grower } from '../../styledComponents'
import SearchIcon from '@mui/icons-material/Search';
import { CheckBoxOutlineBlankRounded, CheckBoxRounded } from '@mui/icons-material';

/* Provides a pick list to select a contact, and an option to add a new one */
export default function ContactSelect(props) {
    const {
        hide,
        title,
        contactList,
        contactSelectedCallback,
        newContactCallback,
        currentSelectedContactId
    } = props;
    const [filterText, setFilterText] = useState('')
    const [filterContactOptions, setFilterContactOptions] = useState(contactList);

    function contactSelected(cont) {
        contactSelectedCallback(cont);
        hide();
    }

    const contacts = filterContactOptions
        .sort(c => c.P21Id !== currentSelectedContactId)
        .map((c, i) => (
            <FlexChild key={c.P21Id} onClick={() => contactSelected(c)}>
                {currentSelectedContactId === c.P21Id ? <CheckBoxRounded /> : <CheckBoxOutlineBlankRounded />}
                <Grower>
                    <SelectedBorder key={i} selected={currentSelectedContactId === c.P21Id} >
                        <ContactBox selectedContact={c} />
                    </SelectedBorder>
                </Grower>
            </FlexChild>
        )
        );

    useEffect(() => {
        if (filterText.trim() === '') {
            setFilterContactOptions(contactList);
            return;
        }

        let _filterText = filterText.toLowerCase();

        setFilterContactOptions(
            contactList.filter((i) => {
                return [i.FirstName, i.LastName].join(' ')
                    .toLowerCase().includes(_filterText) ||
                    i.Title?.toLowerCase().includes(_filterText) ||
                    i.FirstName?.toLowerCase().includes(_filterText) ||
                    i.LastName?.toLowerCase().includes(_filterText) ||
                    i.Email?.toLowerCase().includes(_filterText) ||
                    i.PhoneExt?.toLowerCase().includes(_filterText) ||
                    i.Phone?.toLowerCase().includes(_filterText)
            })
        )
    }, [filterText]);

    return (
        <>
            <h4>{title}</h4>
            <h6>Tap to select a contact to use for this order</h6>
            <Layout>
                <HeaderActionButton onClick={() => { hide(); newContactCallback(); }}>New...</HeaderActionButton>
            </Layout>

            {contactList.length > 0 && <>
                <br />
                <form>
                    <SearchIcon aria-label="Search Contacts List" />
                    <input type="text" value={filterText} onChange={(e) => setFilterText(e.target.value)}></input>
                </form>
                <ScrollContainer>
                    {contacts}
                </ScrollContainer>
            </>}
        </>
    )
}