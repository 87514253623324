import React from 'react'
import styled from 'styled-components'

const ContainerDiv = styled.div`
    display: flex;
    flex-direction: row;
    width: 948px;
    flex-wrap: wrap;
    background-color: white;
    box-shadow: 0px 3px 4px #dadada;
`
const DivOne = styled.div`
    display: flex;
    border: 1px solid lightgray;
    border-top: 0;
    padding: 20px 20px 0;
`
const DivTwo = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #f2f3f4;
    padding: 20px 20px 0;
`
const P = styled.p`
    font-size: 16px;
    margin-bottom: 4px;
`
const H5 = styled.h5`
    color: #000000;
`
const ImgDiv = styled.div`
    margin: 15px 0;
`
const Img = styled.img`
    max-width: 180px;
`
const Ul = styled.ul`
    padding-left: 5px;
    a{
        color: #000000;
    }
`
const Li = styled.li`
    list-style: none;
    padding: 0px 20px;
    background-image: url(
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAARMAAAC3CAMAAAAGjUrGAAAAaVBMVEX///8Qeb0AdrwAdLsAcroAcLkAbrgGd7zx9/vk7/f6/f6Rutzb6fSav9/r8/mjxeG81elxp9PJ3u6ItdkZfr9Rlsthns5ro9HT5PGsy+R/r9bA2OtEkMgqhcN7rdW00Oc5i8ZYms0Aabf4K9icAAAEXElEQVR4nO3diXbqIBAG4LKrdd+1ptX7/g95TdzSBBNM6IkM//cEdA7CMAzpxwcAAAAAAAAAAAAAAAAAAAAAAAAARGDS73oE72enZsOux/B2lkKt5l0P4s2MJOM6+Rl0PY53MlCMMaOWo3HXI3kje8HSqEj2jeX2pq9Zxgi+xsJyteHsGhWujj9dj+Y9bBW7Oy8sk17XA3oHK87yUWELLCwf09xEuSwss23XY+raIDGsEBW5+up6VB2bSFbE5XIXdR43YKYUlDRj+Yw5j1uIckzSqIiID4g9bYtJurCIdbQLy4bbg5IuLMdd16PrxvDJRLkuLKMo87jT04mSRUXuI8zj5qoiJunCojbxHRCPVRPlsrCsYjsg7qonyiUqySSqPK6U4NsXFrGIKY8bWfO2clT0Jp48bmwcJkr2E9Knr1h+Qt9OEyWLijpGsrD0/7nGJKvHfUaRx50cfzwXIoobxG1Fgm+Nio6g0H+oy9uKuD5Qv0Gc1udtpaiohPYB0SlvKzofm0kfEC2FWZeoCH2iW+jvLZvEhGULy5TqwmIvzLpFJSF6g9gXDVaUKyOXNG8Q169ux78IviG4sFQVZl3mitAHeoX+l/O2Iq6PO2ILy1e7iZJNlvMBkVblqUneViLZN6UD4uT1BN/CCEGoxXTQNG8rSltMu/5jfPlslODbpC2mNJbbvq3zoqnzAZHEwjJrnOBbGEmixXToMyZpi6k8TLv+m1pbtc3birhahl7on7fP20okD/yA6CVvKwi9xfTHS95WjkrQLaa+8rZSWGQSbCfYyFveVoqKEoHeIPZ85m0F/F8SZhrnfTu+R0SFmqwM/+i3w/Up2KTWve/iBUbodZi/mtT4L/Ziyb9Drr41v+Z5xsjQ65HS865jVPAXYn7Kj3dcH8PvyPB63OEkbnym/jbi8xFnHfLB785bvmaEIdLztvU1TQi9yn3+vukVpF5vt2m3eEREk3p2um+fr5EoSuf0eMtpYoQI95xnN2qXrxkpg666WrUqJp3TEYJ9oW2q00byQMuK1Y6NpwmXwd9r2c2b5mtGHcg0VhRUPjOumCP6ROCcZzdstBELTeOcZ9egDGuEmtHbah6cn0k+IiLZguJW8/Bq15ah/njn5e6+tDmY5Oab5/A8Px8Ruq9TcpbOq4nhmlD3awXnrvJzDh/L+3zXMizx9395bmVYeu8Lqrh0xRpNqMhar1+f1nOVkCop1qq9Nuci5Aa9Jmpe0RohKL70q1b52toITuqJkqOKa3MjRURbzcP0ab5mFBtFkMNbPCvDchXr1x6ffQ0m3L5ND6yvz4Wi8BipqX45NzFCE2kdaahchpViH+NW8zAu3IemfZsRnWqsfl+bR/elS5tfX1GK4EtaLnLX5rGUFGvd8rW0bzPqrebh9khUGPxTopusDGsU0W8hNZL+myYCTwS8mgmuE/r3ea8YS0XhiYBXi1V0JcVa2HwBAAAAAAAAAAAAAAAAAAAAAAAA4M/8B7ZbM5IUS68aAAAAAElFTkSuQmCC");
    background-repeat: no-repeat;
    background-position: left center;
    background-size: 20px;
   
`
const FirstFlexBox = styled.div`
    width: 216px;
`
const SecondFlexBox = styled.div`
    
`
const ThirdFlexBox = styled.div`
    margin-left: 5px; 
    text-align: center;
`
const ImgDivTwo = styled.div`
    text-align: center;
    margin: 10px 0;
`
const ImgTwo = styled.img`
    max-width: 380px;
`
const YoutubeLink = styled.a`
    text-align: right; 
`
const P2 = styled.p`
    font-size: 14px;
`
const P3 = styled.p`
    text-align: right;
`
const Span = styled.span`
    font-weight: bold;
`
const BorderBottom = styled.div`
    border-bottom: 1px solid #5555;
`
const A = styled.a`
    color: #555;
`
const A1 = styled.a`
    color: #000000;
    &:hover{
        color: #000000;
    }
`

export default function BrandsDropdownMenu() {
  
    return (
        <ContainerDiv>
            <DivOne>
                <FirstFlexBox>
                <A href="/pages/about-us"><H5>About Airline</H5>
                    <ImgDiv>
                        <Img src="https://airlinemedia.airlinehyd.com/Static_pages/about/75-anniversary-gif%20(1).gif"/>
                    </ImgDiv>
                </A>
                    <Ul>
                        <Li><a href="https://info.airlinehyd.com/celebrating-75" target="_blank">Celebrating 75 Years</a></Li>
                        <Li><a href="/pages/about/careers">Careers</a></Li>
                        <Li><a href="https://info.airlinehyd.com/culture-corner" target="_blank">Company Culture</a></Li>
                        <Li><a href="/pages/about/our-history">History</a></Li>
                        <Li><a href="/pages/industries">Industries Served</a></Li>
                        <Li><a href="/about/transactional-services">Transactional Services</a></Li>
                        <Li><a href="/pages/about/quality-policy">Quality Policy</a></Li>
                        <Li><a href="/pages/about/locations">Locations</a></Li>
                    </Ul>
                       
                </FirstFlexBox>
                <SecondFlexBox>
                    <A1 href="/pages/about-us"><P>Why Choose Airline?</P></A1>
                    <A1 href="https://info.airlinehyd.com/book-tech-traveler" target="_blank">
                    <P>Technology Roadshows</P>
                    <ImgDiv>
                        <Img src="https://info.airlinehyd.com/hubfs/artwork%20on%20road%208.png" />
                    </ImgDiv>
                    </A1>
                    <Ul>
                        <Li><a href="https://info.airlinehyd.com/book-tech-traveler" target="_blank">Airline Tech Traveler</a></Li>
                        <Li><a href="https://info.airlinehyd.com/bosch-rexroth-roadshow" target="_blank">Rexroth Connected Hydraulics</a></Li>
                    </Ul>
                    <P>News and Events</P>
                    <Ul>
                        <Li><a href="/pages/about/news">Press Room</a></Li>
                        <Li><a href="/pages/about/events">Events</a></Li>
                    </Ul>
                </SecondFlexBox>

            </DivOne>
            <DivTwo>
                <ThirdFlexBox>
                    <H5>What Does Airline Do?</H5>
                    <P2>We <Span>specify</Span>, <Span>stock</Span>, and offer <Span>support</Span> for the high-tech solutions <br/>
                    all industries need to run at full capacity</P2>
                    <YoutubeLink href="https://info.airlinehyd.com/what-airline-does" target="_blank">
                    <ImgDivTwo>
                        <ImgTwo src="https://8466844.fs1.hubspotusercontent-na1.net/hubfs/8466844/Airline%20Hydraulics%20-%20What%20Do%20They%20Even%20Do.mp4/medium.jpg?t=1673546513100"/>

                    </ImgDivTwo>
                    </YoutubeLink>
                </ThirdFlexBox>
            </DivTwo>


        </ContainerDiv>
    )
}