import React from 'react'
import ReactDatePicker from 'react-datepicker'
import { DivScroll, SchedTable } from '../styledComponents'
import { DATE_FORMAT_DISPLAY } from 'pageComponents/_common/constants/formattingConstants'
import { getImagePath } from 'pageComponents/_common/helpers/generalHelperFunctions'

export default function ShippingScheduleTable(props) {
    const {
        cartItems,
        setItemRequestedDate,
        modalShippingSchedule,
    } = props

    return (
        <DivScroll>
            <SchedTable>
                <thead>
                    <tr>
                        <th colSpan="2">Item</th>
                        <th>Requested Ship Date</th>
                    </tr>
                </thead>
                <tbody>
                    {cartItems.map((item, idx) =>
                        <tr key={item.LineNumber}>
                            <td className="image">
                                <img width="100" src={getImagePath(item.ImageUrl)} alt={item.ItemId} />
                            </td>
                            <td className="itemDesc">
                                <span className="price">
                                    #{item.LineNumber}
                                </span>
                                <br />
                                <span className="itemId">
                                    {item.ItemId}
                                </span>
                                <br />
                                <span className="price">
                                    Qty {item.Quantity}
                                </span>
                            </td>
                            <td>
                                <ReactDatePicker dateFormat={DATE_FORMAT_DISPLAY}
                                    selected={modalShippingSchedule.find(s => s.LineNumber === item.LineNumber).RequestedDate}
                                    onChange={(date) => setItemRequestedDate(item.LineNumber, date)}
                                    minDate={Date.now()}
                                />
                            </td>
                        </tr>
                    )}
                </tbody>
            </SchedTable>
        </DivScroll>
    )
}