import React from 'react'
import { FormikStyleInput } from '../../_common/formik/input_v2'
import { useCheckout2 } from 'setup/CheckoutProviderFields'
import { Area, AreaContentRow, AreaHeading, LayoutArea } from '../styledComponents'
import { CalendarMonthOutlined } from '@mui/icons-material'

export default function QuoteInput(props) {
    const tomorrowDate = new Date().setDate(new Date().getDate() + 1)
    const maxDate = new Date('01 Jan 2970 00:00:00 GMT')
    const {
        checkoutEditableFields,
        setCheckoutEditableFields
    } = useCheckout2();

    return (<Area>
        <AreaHeading>
            <h1>Quote Information</h1>
        </AreaHeading>
        <LayoutArea>
            <AreaContentRow>
                <FormikStyleInput
                    label="Quote Reference"
                    maxLength={40}
                    value={checkoutEditableFields.QuoteReferenceNumber}
                    onChange={(e) => setCheckoutEditableFields({
                        ...checkoutEditableFields,
                        QuoteReferenceNumber: e.target.value
                    })}
                />
            </AreaContentRow>
            <AreaContentRow>
                <FormikStyleInput
                    label={<><CalendarMonthOutlined /> Quote Expiration Date</>}
                    type="datepicker"
                    onChange={(value) => 
                        { console.log('dp on change' ,value);
                        setCheckoutEditableFields({
                        ...checkoutEditableFields,
                        QuoteExpirationDate: value
                    })}}
                    value={checkoutEditableFields.QuoteExpirationDate ? Date.parse(checkoutEditableFields.QuoteExpirationDate) : null}
                    minDate={tomorrowDate}
                    maxDate={maxDate}
                />
            </AreaContentRow>
        </LayoutArea></Area>
    )
}