import React, { lazy, Suspense, useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Helmet } from 'react-helmet'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
    faCheckSquare, faCoffee, faPhoneAlt, faChevronLeft, faChevronRight, faCaretDown, faCaretUp, faShare, faGripLines,
    faLock, faSave, faTimesCircle, faCalendar, faDivide, faShoppingCart, faMapPin, faFax, faSearch, faUserCircle, faTimes,
    faUser, faUserPlus, faGlobeAmericas, faAddressBook, faArrowCircleRight, faPlus, faFileInvoiceDollar, faPlusCircle,
    faMinusCircle, faTools, faPencilAlt, faShippingFast, faEnvelope, faMapMarkerAlt, faPrint, faQuestionCircle, faTruckLoading,
    faUserEdit, faDesktop, faBoxOpen, faDatabase, faHome, faFilePdf, faFileCsv, faFileExcel, faCopy, faList, faEllipsisH,
    faCodeBranch, faCheck, faAsterisk, faFileUpload, faWrench, faTruckMoving, faEye, faEyeSlash, faTrash
} from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faLinkedinIn, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons'
import { ApolloProvider, ApolloClient, ApolloLink, HttpLink, InMemoryCache } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import ContextProvider from './setup/provider'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import { logout } from './pageComponents/_common/helpers/generalHelperFunctions'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { PRIMARY_RED, SECONDARY_GRAY } from './pageComponents/_common/constants/colors'
import './index.css'
import { BrowserRouter, Route, Routes, Navigate, useNavigate } from 'react-router-dom'
// Layouts
import EmptyLayout from './layoutComponents/emptyLayout'
import HeaderFooterHomepageLayout from './layoutComponents/headerfooterLayout/headerfooterHomepageLayout'
import BrandTemplate from 'pageComponents/Brands/brandTemplate'
import "react-datepicker/dist/react-datepicker.css";
import Checkout2 from 'pageComponents/Checkout2/checkout2'

// Components
const About = lazy(() => import('./pageComponents/About/aboutPage'))
const Apps = lazy(() => import('./pageComponents/Apps/appsPage'))
const Blog = lazy(() => import('./pageComponents/Blog/blogPage'))
const Checkout = lazy(() => import('./pageComponents/Checkout/checkoutPage'))
const CreditApplication = lazy(() => import('./pageComponents/CreditApplication/CreditApplicationPage'))
const EngineeredSystemsAndAssemblies = lazy(() => import('./pageComponents/Services/engineeredSystemsAndAssemblies'))
const EngineeredSystems = lazy(() => import('./pageComponents/Services/engineeredSystems'))
const FramingRequest = lazy(() => import('./pageComponents/FramingRequest/FramingRequestPage'))
const GovermentSale = lazy(() => import('./pageComponents/GovermentSales/GovermentSalesPage'))
const Home = lazy(() => import('./pageComponents/Home/homePage'))
const ItemDetail = lazy(() => import('./pageComponents/ItemDetail/itemDetailPage'))
const KnowledgeCenter = lazy(() => import('./pageComponents/KnowledgeCenter/knowledgeCenterPage'))
const LineCards = lazy(() => import('./pageComponents/LineCards/lineCardsPage'))
const Login = lazy(() => import('./pageComponents/Login/loginPage'))
const ManufacturerCatalog = lazy(() => import('./pageComponents/Pages/manufacturerCatalogPage'))
const OrderComplete = lazy(() => import('./pageComponents/Checkout/orderCompletePage'))
const PasswordReset = lazy(() => import('./pageComponents/PasswordReset/passwordReset'))
const PlantServices = lazy(() => import('./pageComponents/Services/plantServices'))
const RedPallet = lazy(() => import('./pageComponents/RedPallet/redPalletPage'))
const SearchResults = lazy(() => import('./pageComponents/SearchResults/searchResultsPage'))
const ServiceForm = lazy(() => import('./pageComponents/ServiceForm/serviceForm'))
const ServiceFormComplete = lazy(() => import('./pageComponents/ServiceForm/serviceFormComplete'))
const Shop = lazy(() => import('./pageComponents/Shop/shopPage'))
const ShoppingCart = lazy(() => import('./pageComponents/ShoppingCart/shoppingCartPage'))
const Signup = lazy(() => import('./pageComponents/Signup/signupPage'))
const StaticPage = lazy(() => import('./pageComponents/Pages/staticPage'))
const ShoppingCartImportPage = lazy(() => import('./pageComponents/ShoppingCart/shoppingCartImportPage'))
// Supporting Components
const PermissionDenied = lazy(() => import('./pageComponents/Error/permissionDenied'))
const FourOFour = lazy(() => import('./pageComponents/Error/fourOFourPage'))
const CategoriesPage = lazy(() => import('./pageComponents/ProductCategories/categoriesPage'))
const PasswordResetPinPage = lazy(() => import('./pageComponents/PasswordReset/PasswordResetPinPage'))
const BrandsPage = lazy(() => import('./pageComponents/Brands/brandsPage'))
const GeneralMinimalBrand = lazy(() => import('pageComponents/Brands/generalMinimalBrand'))
const GeneralFullBrand = lazy(() => import('pageComponents/Brands/generalFullBrand'))
const ContactUsPage = lazy(() => import('./pageComponents/ContactUs/contactUsPage'))
const SupplierLink = lazy(() => import('./pageComponents/ShoppingCart/supplierLink'))
const AdminRoutes = lazy(() => import('./routes/adminRoutes'))
const MyAccountPageRoutes = lazy(() => import('./routes/myAccountPageRoutes'))
const AnonymousRoutes = lazy(() => import('./routes/anonymousRoutes'))
const MobileRoutes = lazy(() => import('./routes/mobileRoutes')
)
library.add(faCheckSquare, faCoffee, faPhoneAlt, faChevronLeft, faChevronRight, faCaretDown, faCaretUp, faShare,
    faGripLines, faLock, faSave, faTimesCircle, faCalendar, faDivide, faShoppingCart, faFacebook, faLinkedinIn, faTwitter,
    faYoutube, faMapPin, faFax, faSearch, faUserCircle, faTimes, faUser, faUserPlus, faGlobeAmericas, faAddressBook,
    faArrowCircleRight, faPlus, faFileInvoiceDollar, faPlusCircle, faMinusCircle, faTools, faPencilAlt, faShippingFast,
    faEnvelope, faMapMarkerAlt, faPrint, faQuestionCircle, faTruckLoading, faUserEdit, faDesktop, faBoxOpen, faDatabase,
    faHome, faFilePdf, faFileCsv, faFileExcel, faCopy, faList, faEllipsisH, faCodeBranch, faCheck, faAsterisk, faFileUpload,
    faWrench, faTruckMoving, faEye, faEyeSlash, faTrash)

const httpLink = new HttpLink({
    uri: `${process.env.REACT_APP_API_URL}/graphql`,
})

const unauthHandler = () => {
    //If we received an unauthorized result from the server, 
    // and we think we're logged in, we need to logout.
    const accessToken = localStorage.getItem('apiToken')
    const refreshToken = localStorage.getItem('refreshToken')
    const userInfo = localStorage.getItem('userInfo')

    if (accessToken || refreshToken || userInfo) {
        logout()
        window.location.reload()
    }
}

const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
        console.log("graphQLErrors", graphQLErrors)
        if (graphQLErrors.some(e => e.extensions.code === "AIRLINE_UNAUTHENTICATED")) {
            unauthHandler()
        }
    } else if (networkError) {
        if (networkError.statusCode === 401) {
            unauthHandler()
        }
    }
})

// Setup the header for the request
const middlewareAuthLink = new ApolloLink((operation, forward) => {
    const token = localStorage.getItem('apiToken')
    const refreshToken = localStorage.getItem('refreshToken')

    //Attach the JWT tokens to every request.
    if (refreshToken && token) {
        operation.setContext({
            headers: {
                authorization: token ? `Bearer ${token}` : null,
                refreshToken: refreshToken ? `RefreshToken ${refreshToken}` : null
            }
        })
    }

    return forward(operation)
})

const afterwareLink = new ApolloLink((operation, forward) => {
    return forward(operation).map((response) => {
        const context = operation.getContext()

        //Look for new API tokens on the response.
        const newAccessToken = context.response.headers.get('accesstoken')
        const newRefreshToken = context.response.headers.get('refreshToken')
        //And set them if present. The Refresh Token generated new tokens.
        if (newAccessToken) {
            //UserInfo is necessary for the site logic. Ensure it is not missing.
            if (!localStorage.getItem('userInfo')) {
                logout()
                window.location.reload()
            }

            localStorage.setItem('apiToken', newAccessToken)
            localStorage.setItem('refreshToken', newRefreshToken)
        }

        return response
    })
})

const client = new ApolloClient({
    link: ApolloLink.from([
        errorLink,
        middlewareAuthLink,
        afterwareLink,
        httpLink
    ]),
    cache: new InMemoryCache()
})

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY)

const defaults = createTheme()
const muiTheme = createTheme(Object.assign({}, defaults, {
    palette: {
        primary: {
            main: PRIMARY_RED
        },
        secondary: {
            main: SECONDARY_GRAY
        }
    },
    overrides: {
        MuiButton: {
            containedPrimary: {
                background: 'linear-gradient(to top left,#950f23,#DB1633)'
            }
        }
    }
}))

const fallback = <div>Loading...</div>
/*
//To be removed when feature AHD-703 is complete 
function TestOrderSummaryPage() {
    const res = {
        WebReferenceId: "ABCDEF",
        CheckoutType: "order",
        AffiliateName: "Dougs Dealz",
        ItemsSubTotal: 100,
        TaxTotal: 6,
        ShippingCost: 12,
        ShippingCarrierName: "UPS 2Day",
        IsPlacedByRep: true,
        ShipTo: {
            Name: "Dougs Dealz",
            Address1: "1 Dim Rd",
            City: "Dimmsdale",
            State: "TN",
            Zip: "00000",
            FirstName: "Doug",
            LastName: "Dimmadome",
            Phone: "123-456-7896",
            UnitOfMeasure: "EA"
        },
        CartItems: [{
            ItemCode: "ITEM 1",
            InvMastUid: 1234,
            Brand: "Phoenix Contact",
            UnitPrice: 20,
            Quantity: 3,
            Description: "Test item 1",
            ImageUrl: null,
            UtmSource: "nexmart444",
            UtmMedium: "retailconnect",
            UtmCampaign: "phoenix_ct1234",
            UnitOfMeasure: "EA"
        },{
            ItemCode: "ITEM 2",
            InvMastUid: 1235,
            Brand: "Phoenix Contact",
            UnitPrice: 20,
            Quantity: 1,
            Description: "Test item 2",
            ImageUrl: null,
            UtmSource: "nexmart444",
            UtmMedium: "retailconnect",
            UtmCampaign: "phoenix_ct1234",
            UnitOfMeasure: "EA"
        },
        {
            ItemCode: "ITEM 3",
            InvMastUid: 1236,
            Brand: "SMC",
            UnitPrice: 6,
            Quantity: 1,
            Description: "Test item 3",
            ImageUrl: null,
            UtmSource: null,
            UtmMedium: null,
            UtmCampaign: null,
            UnitOfMeasure: "EA"
        },
    ]};

    return <Navigate to={`/order-complete/${res.WebReferenceId}`} state={res}/>
}*/

function App() {

    const [helment, setHelment] = useState(null);
    useEffect(() => {
        setTimeout(() => setHelment(<script type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/8466844.js" ></script>), 2000)
    }, [])
    return (
        <>
        <Helmet>
            {helment}
        </Helmet>
        <Routes>
            <Route element={<HeaderFooterHomepageLayout disableSticky={false}/>}>
                <Route index element={<Suspense fallback={fallback}><Home /></Suspense>} />
                {/* <Route path='testSummary' element={<Suspense fallback={fallback}><TestOrderSummaryPage/></Suspense>} /> */}
                <Route path='industries' element={<Suspense fallback={fallback}><Home /></Suspense>} />
                <Route path='resources' element={<Suspense fallback={fallback}><Home /></Suspense>} />
                <Route path='about/:page' element={<Suspense fallback={fallback}><About /></Suspense>} />
                <Route path='apps' element={<Suspense fallback={fallback}><Apps /></Suspense>} />
                <Route path='blog' element={<Suspense fallback={fallback}><Blog /></Suspense>} />
                <Route path='blog/:slug' element={<Suspense fallback={fallback}><Blog /></Suspense>} />
                <Route path='brands' element={<Suspense fallback={fallback}><BrandsPage /></Suspense>} />
                <Route path='brands/:page' element={<Suspense fallback={fallback}><GeneralMinimalBrand /></Suspense>} />
                <Route path='brands/featured/:page' element={<Suspense fallback={fallback}><GeneralFullBrand /></Suspense>} />
                <Route path='categories' element={<Suspense fallback={fallback}><CategoriesPage /></Suspense>} />
                <Route path='categories/:categoryUrlSlug/:urlPage' element={<Suspense fallback={fallback}><CategoriesPage /></Suspense>} />
                <Route path='categories/:categoryUrlSlug/' element={<Suspense fallback={fallback}><CategoriesPage /></Suspense>} />
                <Route path='contact-us' element={<Suspense fallback={fallback}><ContactUsPage /></Suspense>} />
                <Route path='credit-application' element={<Suspense fallback={fallback}><CreditApplication /></Suspense>} />
                <Route path='framing-request' element={<Suspense fallback={fallback}><FramingRequest /></Suspense>} />
                <Route path='government-sales' element={<Suspense fallback={fallback}><GovermentSale /></Suspense>} />
                <Route path='knowledge-center-and-faq' element={<Suspense fallback={fallback}><KnowledgeCenter /></Suspense>} />
                <Route path='linecards' element={<Suspense fallback={fallback}><LineCards /></Suspense>} />
                <Route path='order-complete/:orderId' element={<Suspense fallback={fallback}><OrderComplete /></Suspense>} />
                <Route path='quote-complete/:orderId' element={<Suspense fallback={fallback}><OrderComplete /></Suspense>} />
                <Route path='pages/resources/catalog-request' element={<Suspense fallback={fallback}><ManufacturerCatalog /></Suspense>} />
                <Route path='product/:item/:itemId/:customerPartNumber' element={<Suspense fallback={fallback}><ItemDetail /></Suspense>} />
                <Route path='product/:item/:itemId' element={<Suspense fallback={fallback}><ItemDetail /></Suspense>} />
                <Route path='productinfo/:item/:itemId/:customerPartNumber' element={<Suspense fallback={fallback}><ItemDetail itemView="info" /></Suspense>} />
                <Route path='productinfo/:item/:itemId' element={<Suspense fallback={fallback}><ItemDetail itemView="info" /></Suspense>} />
                <Route path='productcustomizer/:item/:itemId/:customerPartNumber' element={<Suspense fallback={fallback}><ItemDetail itemView="customizer" /></Suspense>} />
                <Route path='productcustomizer/:item/:itemId' element={<Suspense fallback={fallback}><ItemDetail itemView="customizer" /></Suspense>} />
                <Route path='search/' element={<Suspense fallback={fallback}><SearchResults /></Suspense>} />
                <Route path='search/:searchTerm' element={<Suspense fallback={fallback}><SearchResults /></Suspense>} />
                <Route path='search/categories/:parentCategory' element={<Suspense fallback={fallback}><SearchResults /></Suspense>} />
                <Route path='search/categories/:parentCategory/:childCategory' element={<Suspense fallback={fallback}><SearchResults /></Suspense>} />
                <Route path='services/engineered-systems-and-assemblies' element={<Suspense fallback={fallback}><EngineeredSystemsAndAssemblies /></Suspense>} />
                <Route path='services/engineered-systems-and-assemblies/:page' element={<Suspense fallback={fallback}><EngineeredSystems /></Suspense>} />
                <Route path='services/plant-services/:page' element={<Suspense fallback={fallback}><PlantServices /></Suspense>} />
                <Route path='shop/:page' element={<Suspense fallback={fallback}><Shop /></Suspense>} />
                <Route path='cart' element={<Suspense fallback={fallback}><ShoppingCart /></Suspense>} />
                <Route path='cart/supplierLink/:referrer' element={<Suspense fallback={fallback}><SupplierLink /></Suspense>} />
                <Route path='cart/import' element={<Suspense fallback={fallback}><ShoppingCartImportPage /></Suspense>} />
                <Route path='serviceform-complete/:formNo' element={<Suspense fallback={fallback}><ServiceFormComplete /></Suspense>} />
                <Route path='technologies/:page' element={<Suspense fallback={fallback}><technologyPage /></Suspense>} />
                <Route path='pages' element={<Suspense fallback={fallback}><Home /></Suspense>} />
                <Route path='pages/:pageId1' element={<Suspense fallback={fallback}><StaticPage /></Suspense>} />
                <Route path='pages/:pageId1/:pageId2' element={<Suspense fallback={fallback}><StaticPage /></Suspense>} />
                <Route path='pages/:pageId1/:pageId2/:pageId3' element={<Suspense fallback={fallback}><StaticPage /></Suspense>} />
                <Route path='pages/:pageId1/:pageId2/:pageId3/:pageId4' element={<Suspense fallback={fallback}><StaticPage /></Suspense>} />
            </Route>
            <Route element={<HeaderFooterHomepageLayout disableSticky={true} />}>
                <Route path='checkout' element={<Suspense fallback={fallback}><Checkout /></Suspense>} />
                <Route path='checkout2' element={<Suspense fallback={fallback}><Checkout2 /></Suspense>} />
                <Route path='create-quote' element={<Suspense fallback={fallback}><Checkout /></Suspense>} />
                <Route path='create-quote2' element={<Suspense fallback={fallback}><Checkout2 /></Suspense>} />
                <Route path='serviceform' element={<Suspense fallback={fallback}><ServiceForm /></Suspense>} />
                {/* Error Screens */}
                <Route path='permission-denied' element={<Suspense fallback={fallback}><PermissionDenied /></Suspense>} />
                <Route path='*' element={<Suspense fallback={fallback}><FourOFour /></Suspense>} />
                <Route path='life-science' element={
                    <Suspense fallback={fallback}>
                        <StaticPage
                            override={{ pageId1: 'industries', pageId2: "life-sciences", pageId3: null, pageId4: null }}
                        />
                    </Suspense>}
                />
                <Route path='brands' element={
                    <Suspense fallback={fallback}>
                        <StaticPage
                            override={{ pageId1: 'brands', pageId2: null, pageId3: null, pageId4: null }}
                        />
                    </Suspense>}
                />
            </Route>
            <Route element={<EmptyLayout />} >
                <Route path='password-reset' element={<Suspense fallback={fallback}><PasswordResetPinPage /></Suspense>} />
                <Route path='signup' element={<Suspense fallback={fallback}><Signup /></Suspense>} />
                <Route path='login' element={<Suspense fallback={fallback}>< Login /></Suspense>} />
                <Route path='red-pallet' element={<Suspense fallback={fallback}>< RedPallet /></Suspense>} />
                {/*deprecated*/} <Route exact path='password-reset/:passwordToken' element={<Suspense fallback={fallback}><PasswordReset /></Suspense>} />
            </Route >
            <Route path='account/:page/*' element={<Suspense fallback={fallback}><MyAccountPageRoutes /></Suspense>} />
            <Route path="/admin-dashboard/*" element={<Suspense fallback={fallback}><AdminRoutes /></Suspense>} />
            <Route path="/anonymous/*" element={<Suspense fallback={fallback}><AnonymousRoutes /></Suspense>} />
            <Route path="/mobile/*" element={<Suspense fallback={fallback}><MobileRoutes /></Suspense>} />
        </Routes >
        </>
    )
}

ReactDOM.render(
    <ThemeProvider theme={muiTheme}>
        <Helmet>
            <title>Airline Hydraulics</title>
        </Helmet>
        <ApolloProvider client={client}>
            <BrowserRouter>
                <ContextProvider>
                    <Elements stripe={stripePromise}>
                        <App />
                    </Elements>
                </ContextProvider>
            </BrowserRouter >
        </ApolloProvider>
    </ThemeProvider >, document.getElementById('index')
)
