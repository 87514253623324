import React from 'react'
import ItemShippingTable from '../components/ItemShippingTable'
import { Link } from 'react-router-dom'
import { AreaContentRow, AreaHeading, HeaderActionButton, Area } from '../styledComponents'

export default function CartItems(props) {
    return (
        <Area>
            <AreaHeading>
                <h1>Your Items</h1>
                <Link to='/cart'>
                    <HeaderActionButton>Edit Cart...</HeaderActionButton>
                </Link>
            </AreaHeading>
            <AreaContentRow>
                <ItemShippingTable />
            </AreaContentRow>
        </Area>
    )
}
