import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useLazyQuery } from '@apollo/client'
import { GET_BRANDEDITOR } from '../../setup/providerGQL'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useParams } from 'react-router'

export function rowsToObj(arr) {

    const rtnObj = {};
    const keys = new Set()

    for (const row of arr) {
        keys.add(row.key)
    }

    for (const key of keys) {
        const filter = arr.filter(a => a.key == key).sort((a,b) => a.sort > b.sort)
        const map = filter.map(a => a.value)
        rtnObj[`${key}`] = map
    }
    return rtnObj;
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
`
const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    margin-top: 15px;
`
const Div1 = styled.div`

`
const ContentDiv = styled.div`
    display: flex;
    flex-direction: column;
`
const Div = styled.div`
    display: flex;
    justify-content: center;
`
const A = styled.a`
    display: flex;
    justify-content: center;
    margin-top: 40px;
    margin-bottom: 20px;
    color: rgb(219, 22, 51);
`
const CricleDiv = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-top: 25px;
    margin-bottom: 25px;

`
const RelatedLinkCircle = styled.div`
  margin: 0 auto;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
 `
const LinkStyle = styled.a`
  color: #246696;
  font-size: 14px;
  position: absolute;
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  padding: 0 15px;
  background-color: #f2f3f4;
  width: 200px;
  height: 200px;    
  border-radius: 50%;
  &:hover{
    color: #133752 ;
    text-decoration: none;
  }
 `
const FontAwesomeDiv = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 20px;
  color: #DB1633;
  &:hover{
  color: #555555;
  }
`
const LinkStyleDiv = styled.div`
  display: flex;
  justify-content: center;
  height: 200px;
  width: 200px;
`
const Img = styled.img`
  max-width: 170px;
  max-height: 110px;
`
const ImgDiv = styled.div`
    margin-right: 45px;
`
const Banner = styled.div`

`
const DivRowHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-item: center;
  text-transform: uppercase;
  font-size: 32px;
  letter-spacing : 2px;
  font-family: verdana;
  color: #333;
  background-image:  linear-gradient( rgba(0,0,0,0),rgba(0,0,0,0.3) ),url(${props => props.url});
  background-size: cover;
  background-position: 50% 0;
  background-repeat: no-repeat;
  height: ${props => props.isLargerBanner ? '290px' : '200px'};
  margin-bottom: 10px;
  width: 100%;
  @media (max-width: 768px) {
    height: 240px; 
    }
  @media (max-width: 400px) {
    height: 190px;
    }
`

const ShortBorder = styled.div`
    border-bottom: 3px solid #B51F2B;
    width: 80%;
    margin: 15px auto;
    padding: 5px;
`

const Video = styled.video`
    position:relative;
    z-index:0;
	width: 100%;
	object-fit: cover;
    height: ${props => props.isLargerBanner ? '290px' : '200px'};
    @media (max-width: 768px) {
		height: 240px;
	}
	@media (max-width: 400px) {
		height: 190px;
	}
`
const VideoContainer = styled.div`
    position: relative;
    width: 100%;
    object-position: center;

`
const VideoText = styled.h1`
    font-weight: 600;
    font-size: 2.5rem;
    text-align: center;
    text-transform: uppercase;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
 
`

export default function BrandTemplate({ brandEditor }) {
    // DO NOT ENTER ANY HOOKS (useEffect, useQuery etc)
    // this will cause a uneven rerendering and brick the session
    const fallbackUrl = "https://airlinemedia.airlinehyd.com/Static_pages/Brands/brands-bg.jpg"
    const mediaSize = window.innerWidth < 400 ? 'BANNER_SM' : window.innerWidth < 768 ? 'BANNER_MD' : 'BANNER_LG'

    function mediaCheck() {
        if (brandEditor?.[mediaSize]?.[0]?.length > 0) {
            return brandEditor[mediaSize]
        } else {
            if (brandEditor?.BANNER_SM?.[0]?.length > 0) {
                return brandEditor.BANNER_SM
            }
            if (brandEditor?.BANNER_MD?.[0]?.length > 0) {
                return brandEditor.BANNER_MD
            }
            if (brandEditor?.BANNER_LG?.[0]?.length > 0) {
                return brandEditor.BANNER_LG
            }
        }
    }
    const videoSource = mediaCheck()
    return (
        <Container>
            <Banner>
                {(brandEditor?.[mediaSize]?.includes(".mp4")) ? //expand extensions as required with an OR
                    (<>
                        <VideoContainer>
                            <VideoText>
                                {brandEditor?.URL_SLUG[0].replaceAll("-", " ")}
                                <ShortBorder />
                            </VideoText>
                            <Video isLargerBanner={!!brandEditor?.[mediaSize]} src={videoSource} autoPlay loop muted></Video>
                        </VideoContainer>

                    </>)
                    :
                    (<VideoContainer >
                        <VideoText>
                            {brandEditor?.URL_SLUG?.[0].replaceAll("-", " ")}
                            <ShortBorder />
                        </VideoText>
                        <DivRowHeader url={videoSource || fallbackUrl} isLargerBanner={!!brandEditor?.[mediaSize]}></DivRowHeader>
                    </VideoContainer>)
                }
            </Banner>
            <ContentContainer>
                <Div>
                    {(brandEditor?.LOGO_PATH > [0] ?
                        (<ImgDiv>
                            <Img src={`https://${brandEditor?.LOGO_PATH}`} alt="brand-logo" />
                        </ImgDiv>)
                        :
                        (<></>)
                    )}
                    <Div1>
                        <ContentDiv>
                            {brandEditor?.INTRO_PARAGRAPH?.map(
                                (i, index) => {
                                    if(i.length <= 0) return;
                                    return <p key={index}>{i}</p>
                                }
                            )}
                        </ContentDiv>
                        <CricleDiv>
                            <RelatedLinkCircle>
                                <LinkStyleDiv>
                                    <LinkStyle href={brandEditor?.MANUFACTURER_URL} target="_blank" rel="noreferrer">
                                        <FontAwesomeDiv>
                                            <FontAwesomeIcon icon="globe-americas" size='4x' />
                                        </FontAwesomeDiv>
                                        Website
                                    </LinkStyle>
                                </LinkStyleDiv>

                            </RelatedLinkCircle>
                            <RelatedLinkCircle>
                                <LinkStyleDiv>

                                    <LinkStyle href={brandEditor?.PRODUCTS_URL}>
                                        <FontAwesomeDiv>
                                            <FontAwesomeIcon icon="shopping-cart" size='4x' />
                                        </FontAwesomeDiv>
                                        Shop product
                                    </LinkStyle>
                                </LinkStyleDiv>
                            </RelatedLinkCircle>
                            <RelatedLinkCircle>

                                {(brandEditor?.CATALOG_URL > [0] ?
                                    (<>
                                        <LinkStyleDiv>

                                            <LinkStyle href={brandEditor?.CATALOG_URL}>
                                                <FontAwesomeDiv>
                                                    <FontAwesomeIcon icon="address-book" size='4x' />
                                                </FontAwesomeDiv>
                                                Catalog
                                            </LinkStyle>
                                        </LinkStyleDiv>
                                    </>)
                                    :
                                    (<LinkStyleDiv>

                                        <LinkStyle href='/pages/resources/catalog-request'>
                                            <FontAwesomeDiv>
                                                <FontAwesomeIcon icon="address-book" size='4x' />
                                            </FontAwesomeDiv>
                                            Catalog
                                        </LinkStyle>
                                    </LinkStyleDiv>)
                                )}
                            </RelatedLinkCircle>
                        </CricleDiv>
                    </Div1>
                </Div>
            </ContentContainer>
        </Container>
    )
}
