import React from 'react'
import PropTypes from 'prop-types'
import NumberFormat from 'react-number-format'
import { FormikFormFieldContainer, FormikFormFieldLabel, FormikFormFieldError, FormikFormField } from 'styles/formikForm'
import { ErrorMessage, Field, useFormikContext } from 'formik'
import ReactDatePicker from 'react-datepicker'
import { DATE_FORMAT_DISPLAY } from '../constants/formattingConstants'

const input = props => <Field {...props} />

export default function Input(props) {
    const { type, disabled, name, label, placeholder, width, maxLength, style, onChange, value } = props;
    //const {errors, touched} = useFormikContext();

    if (type === 'text' || type === 'email' || type === 'password' || type === 'number') {
        return (
            <FormikFormFieldContainer style={{ ...style, maxWidth: '100%' }}>
                {label && <FormikFormFieldLabel htmlFor={name}>{label}</FormikFormFieldLabel>}
                <FormikFormField
                    type={type}
                    name={name}
                    id={name}
                    placeholder={placeholder}
                    disabled={disabled}
                    style={{ width: width || '400px', maxWidth: '100%' }}
                    maxLength={maxLength}
                    {
                    ...(onChange ? {
                        as: input,
                        onChange,
                        value
                    } : {})
                    }
                />
                <FormikFormFieldError style={{ width: width || '400px', maxWidth: '100%' }}>
                    <ErrorMessage name={name} />
                </FormikFormFieldError>
            </FormikFormFieldContainer>
        )
    } else if (type === 'currency') {
        return (
            <FormikFormFieldContainer style={{ ...style, maxWidth: '100%' }}>
                {label && <FormikFormFieldLabel htmlFor={name}>{label}</FormikFormFieldLabel>}
                <FormikFormField name={name} style={{ maxWidth: '100%' }}>
                    {({
                        field,
                        form
                    }) => (
                        <NumberFormat
                            id={name}
                            {...field}
                            value={field.value}
                            displayType={'input'}
                            thousandSeparator={true}
                            prefix={'$'}
                            decimalScale={2}
                            fixedDecimalScale
                            style={{ width: width || '400px', maxWidth: '100%' }}
                            onChange={e => form.setFieldValue(field.name, parseFloat(e.target.value.replace(/[$,]/g, "")))}
                        />
                    )}
                </FormikFormField>
            </FormikFormFieldContainer>
        )
    } else if (type === 'phone') {
        return (
            <FormikFormFieldContainer style={{ ...style, maxWidth: '100%' }}>
                {label && <FormikFormFieldLabel htmlFor={name}>{label}</FormikFormFieldLabel>}
                <FormikFormField name={name} style={{ maxWidth: '100%' }}>
                    {({
                        field,
                        form
                    }) => (
                        <NumberFormat
                            id={name}
                            {...field}
                            type='tel'
                            format='###-###-####'
                            placeholder='###-###-####'
                            mask='_'
                            value={field.value}
                            displayType={'input'}
                            style={{ width: width || '400px', maxWidth: '100%' }}
                            onChange={e => form.setFieldValue(field.name, e.target.value.replace(/[_]/g,''))}
                        />
                    )}
                </FormikFormField>
                <FormikFormFieldError style={{ width: width || '400px', maxWidth: '100%' }}>
                    <ErrorMessage name={name} />
                </FormikFormFieldError>
            </FormikFormFieldContainer>
        )
    } else {
        return (
            <FormikFormFieldContainer style={{ ...style, maxWidth: '100%' }}>
                {label && <FormikFormFieldLabel htmlFor={name}>{label}</FormikFormFieldLabel>}
                <FormikFormField id={name} type={type} name={name} />
                <FormikFormFieldError style={{ width: width || '200px', maxWidth: '100%' }}>
                    <ErrorMessage name={name} />
                </FormikFormFieldError>
            </FormikFormFieldContainer>
        )
    }
}

/*Creates a non-formik bound input, but styled to look the same */
export function FormikStyleInput({ type, value, disabled, name, label, placeholder, width, maxLength, onChange, alignment, minDate, maxDate }) {
    if (type === 'currency') {
        return (
            <FormikFormFieldContainer style={{ maxWidth: '100%' }}>
                {label && <FormikFormFieldLabel htmlFor={name} style={{ textAlign: alignment, width: '100%', paddingRight: 7 }}>{label}</FormikFormFieldLabel>}
                <NumberFormat
                    id={name}
                    name={name}
                    value={value}
                    displayType={'input'}
                    thousandSeparator={true}
                    prefix={'$'}
                    decimalScale={2}
                    fixedDecimalScale
                    style={{ width: width || '400px', maxWidth: '100%', textAlign: 'right' }}
                    onChange={e => onChange(e, e.target.value, parseFloat(e.target.value.replace(/[$,]/g, "")))}
                />
            </FormikFormFieldContainer>
        )
    } else if (type === 'phone') {
        return (
            <FormikFormFieldContainer style={{ width: width || '400px', maxWidth: '100%' }}>
                {label && <FormikFormFieldLabel htmlFor={name}>{label}</FormikFormFieldLabel>}
                <NumberFormat
                    id={name}
                    name={name}
                    type='tel'
                    format='###-###-####'
                    placeholder='###-###-####'
                    mask='_'
                    value={value}
                    displayType={'input'}
                    style={{ width: width || '400px', maxWidth: '100%' }}
                    onChange={e => onChange(e, e.target.value.replace(/[_]/g,''))}
                />
            </FormikFormFieldContainer>
        )
    } else if(type==='datepicker') {
        return ( <FormikFormFieldContainer style={{ width: width || '400px', maxWidth: '100%' }}>
            {label && <FormikFormFieldLabel htmlFor={name}>{label}</FormikFormFieldLabel>}
            <ReactDatePicker
                dateFormat={DATE_FORMAT_DISPLAY}
                minDate={minDate}
                maxDate={maxDate}
                selected={value}
                onChange={onChange}
                style={{ width: width || '400px', maxWidth: '100%' }}
             />
        </FormikFormFieldContainer>);
    } else {
        return (
            <FormikFormFieldContainer style={{ width: width || '400px', maxWidth: '100%' }}>
                {label && <FormikFormFieldLabel htmlFor={name} style={{ textAlign: alignment, width: '100%' }}>{label}</FormikFormFieldLabel>}
                <input
                    type={type}
                    name={name}
                    id={name}
                    placeholder={placeholder}
                    disabled={disabled}
                    style={{ width: width || '400px', maxWidth: '100%' }}
                    maxLength={maxLength}
                    onChange={onChange}
                    value={value}
                />
            </FormikFormFieldContainer>
        )
    }
}

export function FormikStyleLabel({ name, label, children, alignment, width }) {
    return (
        <FormikFormFieldContainer style={{ maxWidth: '100%', width: width }}>
            {label && <FormikFormFieldLabel style={{ textAlign: alignment, width: '100%' }} htmlFor={name}>{label}</FormikFormFieldLabel>}
            {children}
        </FormikFormFieldContainer>
    )
}

Input.propTypes = {
    name: PropTypes.string.isRequired,
    type: PropTypes.string,
    disabled: PropTypes.bool,
    label: PropTypes.any,
    placeholder: PropTypes.string,
    width: PropTypes.number,
    maxLength: PropTypes.number,
}

Input.defaultProps = {
    type: 'text',
    placeholder: '',
    maxLength: null
}