import React from 'react'
import styled from 'styled-components'


const ContainerDiv = styled.div`
    display: flex;
    width: 488px;
    background-color: white;
    border-top: 0;
    justify-content: space-between;
    box-shadow: 0px 3px 4px #dadada;
`
const Div1 = styled.div`
    display: flex;
    flex-direction: column;
    padding: 5px 23px 15px;
    border: 1px solid lightgray;
    border-top: 0;
`
const Div2 = styled.div`
    display: flex;
    flex-direction: column;
    align-item: center;
    text-align: center;
    justify-content: center;
    background-color: #f2f3f4;
    
`
const ImgDiv = styled.div`
    padding: 0 45px;
`
const Img = styled.img`
    width: 100%;
    max-width: 105px;
`
const P = styled.p`
    font-weight: bold;
    margin-bottom: 0;
    a {
        color: #555;
    }
`
const Group = styled.div`
    display: flex;
    align-items: center;
`
const IconDiv = styled.div`
    margin-right: 20px;
`
const Icon = styled.img`
    width: 100%;
    max-width: 60px;
    margin: 15px 0;

`
const P1 = styled.p`
    margin-top: 15px;
    font-weight: bold;
    a {
        color: #555;
    }
`
const A = styled.a`
    color: #555;
    display: flex;
    align-items: center;
    &:hover{
        text-decoration: none;
    }
`
const A2 = styled.a`
    color: #555;
    &:hover{
        text-decoration: none;
    }
`
export default function ResourcesDropdownMenu() {

    return (
        <ContainerDiv>
            <Div1>
                <Group>
                    <A href="https://blog.airlinehyd.com/" target='_blank'>
                        <IconDiv>
                            <Icon src="https://airlinemedia.airlinehyd.com/Static_pages/icon/blog.png" />
                        </IconDiv>
                        <P>Blog: Technically Speaking</P>
                    </A>
                </Group>
                <Group>
                    <A href="/pages/resources/linecards">
                        <IconDiv>
                            <Icon src="https://airlinemedia.airlinehyd.com/Static_pages/icon/LineCard.png" />
                        </IconDiv>
                        <P>Linecards &amp; Brochures</P>
                    </A>
                </Group>
                <Group>
                    <A href="/srf/SmcUSA" target='_blank'>
                        <IconDiv>
                            <Icon src="https://airlinemedia.airlinehyd.com/Static_pages/icon/smc.png" />
                        </IconDiv>
                        <P>SMC Product Configurator</P>
                    </A>
                </Group>
            </Div1>

            <Div2>
                <A2 href="/pages/resources/resources">
                    <ImgDiv>
                        <Img src="https://airlinemedia.airlinehyd.com/Static_pages/icon/all_resources.png" />
                    </ImgDiv>
                    <P1>View All Resources</P1>
                </A2>
            </Div2>

        </ContainerDiv>
    )
}