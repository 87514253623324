import React, { useContext, useEffect, useState } from 'react'
import { AreaContentRow, AreaHeading, HorizontalPackCenter, LayoutArea, StickyArea, StyledCheckbox } from '../styledComponents';
import { CheckCircle } from '@mui/icons-material';
import { Divider, Stack } from '@mui/material';
import OrderSummaryTable from '../components/OrderSummaryTable';
import MyContext from 'setup/context';
import { useCheckout2 } from 'setup/CheckoutProviderFields';
import { ClickableErrorAlert, ErrorAlert } from 'styles/alerts';
import styled from 'styled-components';
import { ButtonRed } from 'styles/buttons';
import NcnrAcknowledgement from '../components/NcnrAcknowledgement';
import Tooltip from '@mui/material/Tooltip';

const Columns = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`

const Column = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 0px;
    margin-right: 20px;
    margin-top: auto;
    margin-bottom: auto;
`

export default function Checkout2Summary() {
    const { userInfo } = useContext(MyContext);
    const {
        checkoutInitializeDataIsLoading,
        checkoutEditableFields,
        checkoutSaveIsLoading,
        isApproveOrder,
        setIsApproveOrder,
        validationStatus,
        submitOrder,
        inPageRefs,
        openEditors,
        getFirstOpenEditorInPageRef,
        acknowledgeNcnr,
        containNcnrItem,
        setAcknowledgeNcnr,
    } = useCheckout2();

    function calcHasOpenEditor() {
        return Object.values(openEditors).some(item => item === true);
    }

    const [hasOpenEditor, setHasOpenEditor] = useState(calcHasOpenEditor());
    useEffect(() => {
        setHasOpenEditor(calcHasOpenEditor());
    }, [openEditors]);

    useEffect(()=>{
        if (!containNcnrItem){
            setAcknowledgeNcnr(true)
        }
    },[containNcnrItem])

    const submitButtonText = checkoutEditableFields.IsQuote ? 'Submit Quote' : 'Submit Order';
    const isDisabled = checkoutInitializeDataIsLoading || checkoutSaveIsLoading || validationStatus.IsReadyToCheckout === false || hasOpenEditor || (validationStatus.IsPOValid?.Status !== "Valid") || (containNcnrItem && !acknowledgeNcnr);
    
	function submitMessage(){
            const rtn = [];
            if(containNcnrItem && !acknowledgeNcnr) {
                rtn.push("Please acknowledge NCNR statement above");
            }
            if(validationStatus.IsPOValid?.Status === "Empty"){
                rtn.push("Po number is empty");
            }
            if(!validationStatus.IsPOValid?.Status === "Duplicate"){
                rtn.push("Duplicate Po number");
            }   
            return rtn.join("\n");
    }
	const disableMessage = submitMessage()
	const contactValid = validationStatus.IsContactValid && !openEditors.contact;
	const shipToValid = validationStatus.IsShipToValid && !openEditors.shippingAddress && !openEditors.shippingOptions;
	const billToValid = validationStatus.IsBillToValid && !openEditors.billing && !openEditors.billingAddress;

    return (
        <StickyArea>
            <AreaHeading>
                <h1>Summary</h1>
            </AreaHeading>
            <LayoutArea>
                <AreaContentRow>
                    <Columns>
                        <Column>
                            <Stack style={{ cursor: "pointer" }} direction={'row'} onClick={() => inPageRefs.contactSectionRef.current.scrollIntoView({ behavior: 'smooth' })}>
                                <CheckCircle titleAccess={contactValid ? 'Contact section is valid' : 'Contact section is not valid'} label='Contact' htmlColor={contactValid ? 'green' : '#CDC'} />
                                <label>Contact</label>
                            </Stack>
                            <Stack style={{ cursor: "pointer" }} direction={'row'} onClick={() => inPageRefs.shippingAddressSectionRef.current.scrollIntoView({ behavior: 'smooth' })}>
                                <CheckCircle titleAccess={shipToValid ? 'Ship To section is valid' : 'Ship To section is not valid'} label='Ship to is Valid' htmlColor={shipToValid ? 'green' : '#CDC'} />
                                <label>Ship To</label>
                            </Stack>
                            {!checkoutEditableFields.IsQuote && 
							<Stack style={{ cursor: "pointer" }} direction={'row'} onClick={() => inPageRefs.shippingChoicesSectionRef.current.scrollIntoView({ behavior: 'smooth' })}>
                                <CheckCircle titleAccess={validationStatus.IsShippingOptionValid ? 'A shipping option is selected' : 'A shipping option is not selected'} label='Shipping Option is Selected' htmlColor={validationStatus.IsShippingOptionValid ? 'green' : '#CDC'} />
                                <label>Shipping</label>
                            </Stack>}
							{!checkoutEditableFields.IsQuote &&
                            <Stack style={{ cursor: "pointer" }} direction={'row'} onClick={() => inPageRefs.paymentSectionRef.current.scrollIntoView({ behavior: 'smooth' })}>
                                <CheckCircle titleAccess={validationStatus.IsPaymentValid ? 'Payment method is valid' : 'Payment method is not valid'} label='Payment is Valid' htmlColor={validationStatus.IsPaymentValid ? 'green' : '#CDC'} />
                                <label>Payment</label>
                            </Stack>}
                            <Stack style={{ cursor: "pointer" }} direction={'row'} onClick={() => inPageRefs.billingAndOtherSectionRef.current.scrollIntoView({ behavior: 'smooth' })}>
                                <CheckCircle titleAccess={billToValid ? 'Bill To section is valid' : 'Bill To section is not valid'} label='Bill to is Valid' htmlColor={billToValid ? 'green' : '#CDC'} />
                                <label>Bill To</label>
                            </Stack>
                            {validationStatus.NewAccountStatus.Status !== 'Disabled' /*Only show this if NewAccountStatus indicates action required there */ &&
                                <Stack style={{ cursor: "pointer" }} direction={'row'} onClick={() => inPageRefs.newAccountSectionRef.current.scrollIntoView({ behavior: 'smooth' })}>
                                    <CheckCircle titleAccess={validationStatus.NewAccountStatus.Status === "Ok" ? 'Create new account' : 'New Account Password is not valid'} label='New Account is valid' htmlColor={validationStatus.NewAccountStatus.Status === "Ok" ? 'green' : '#CDC'} />
                                    <label>New Account</label>
                                </Stack>
                            }
                        </Column>
                        <Divider flexItem orientation='vertical'></Divider>
                        <Column>
                            <OrderSummaryTable />
                        </Column>
                    </Columns>
                </AreaContentRow>
                {userInfo?.isImpersonatorUser && !checkoutEditableFields.IsQuote &&
                    <AreaContentRow>
                        <label htmlFor="isApproveOrder">Approve Order</label>
                        <StyledCheckbox
                            id="isApproveOrder"
                            name="isApproveOrder"
                            type="checkbox"
                            checked={isApproveOrder}
                            onChange={(e) => setIsApproveOrder(e.target.checked)}
                        />
                    </AreaContentRow>}
                {/* If we're ready to checkout, but the user has some kind of pending edit, prevent the checkout */}
                {validationStatus.IsReadyToCheckout === true && hasOpenEditor &&
                    <AreaContentRow>
                        <ClickableErrorAlert onClick={() => getFirstOpenEditorInPageRef().current.scrollIntoView({ behavior: 'smooth' })}>There are unsaved changes. Please save before finishing checkout. <br />&raquo; Go there &laquo;</ClickableErrorAlert>
                    </AreaContentRow>
                }
                {/* If we're ready to checkout, but the user is using duplicate PO Number, prevent the checkout */}
                {(validationStatus.IsPOValid?.Status === "Duplicate") 
					?
                    <AreaContentRow>
                        <ClickableErrorAlert onClick={() => document.getElementById('poNumber').scrollIntoView({ behavior: 'smooth' })}>Please confirm duplicate PO Number usage. <br />&raquo; Go there &laquo;</ClickableErrorAlert>
                    </AreaContentRow>
					: 
					((validationStatus.IsPOValid?.Status && validationStatus.IsPOValid.Status !== "Valid") &&
                    <AreaContentRow>
                        <ClickableErrorAlert onClick={() => document.getElementById('poNumber').scrollIntoView({ behavior: 'smooth' })}>Please check the PO Number<br />&raquo; Go there &laquo;</ClickableErrorAlert>
                    </AreaContentRow>
					)
                }
                {validationStatus.IsNotReadyToCheckoutReasons?.length > 0 &&
                    validationStatus.IsNotReadyToCheckoutReasons.map((msg, idx) => {
                        return <AreaContentRow>
                            <ErrorAlert>{msg}</ErrorAlert>
                        </AreaContentRow>
                    })
                }
                <NcnrAcknowledgement />
                <AreaContentRow>
                    <HorizontalPackCenter>
                        <Tooltip title={disableMessage}>
                            <span>
                                <ButtonRed
                                    variant="contained"
                                    color="primary"
                                    disabled={isDisabled}
                                    onClick={submitOrder}
                                >
                                    {submitButtonText}
                                </ButtonRed>
                            </span>
                        </Tooltip>

                    </HorizontalPackCenter>
                </AreaContentRow>
            </LayoutArea>
        </StickyArea>);
}