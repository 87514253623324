import gql from 'graphql-tag'

export const FRAGMENT_ITEM_DETAIL = gql`
    fragment ItemDetails on ItemGraphType {
        availability
        brandId
        dateCreated
        dateModified
        extendedDesc
        hideOnWeb
        invMastUid
        itemCode
        itemCodeUrlSanitized
        itemDesc
        mfgPartNo
        nsn
        modelCode
        popularity
        relevancy
        supplierId
        tariff
        unitSizeMultiple
        itemFamilies {
            familyInvMastUid
        }
        isAssembly
        isNcnr
        isLtlOnly
        isDiscontinued
        hasRelations
        categoryBreadcrumbs {
            name
            urlSlug
        }
        restrictedQty
        restrictedCustomers {
            name
            customerIdP21
        }
        barcode
    }
`

export const FRAGMENT_ITEM_DETAIL_BRANDS = gql`
    fragment Brands on ItemGraphType {
        brand {
            id
            name
            brandPromoLink
            media {
                imageHeight
                imageWidth
                imagePath
                svgAspectRatio
                svgPath
            }
        }
    }
`

export const FRAGMENT_ITEM_DETAIL_FEATURES = gql`
    fragment Features on ItemGraphType {
        itemFeatures {
            createDate
            createdBy
            invMastUid
            lastModifiedDate
            modifiedBy
            sequence
            text
            type
            id
        }
    }
`

export const FRAGMENT_ITEM_DETAIL_MEDIA = gql`
    fragment Media on ItemGraphType {
        itemMedia {
            path
            sequence
            itemMediaType
            mediaType
            mediaId
            altText
            pixelWidth
            pixelHeight
        }
    }
`

export const FRAGMENT_ITEM_DETAIL_ASSOCIATED_ITEMS = gql`
    fragment AssociatedItems on ItemGraphType {
        associatedItems {
            associatedInvMastUid
            createDate
            createdBy
            invMastUid
            lastModifiedDate
            modifiedBy
            quantity
            type
            id
        }
    }
`

export const FRAGMENT_ITEM_DETAIL_ITEM_LINKS = gql`
    fragment ItemLinks on ItemGraphType {
        itemLinks {
            audienceType
            createDate
            createdBy
            invMastUid
            lastModifiedDate
            linkPath
            linkType
            modifiedBy
            sequence
            thumbnail
            title
            id
        }
    }
`

export const FRAGMENT_ITEM_DETAIL_TECH_SPECS = gql`
    fragment TechSpecs on ItemGraphType {
        itemTechSpecs {
            attributeId
            createDate
            createdBy
            invMastUid
            lastModifiedDate
            modifiedBy
            name
            sequence
            id
            value
        }
    }
`

export const FRAGMENT_ITEM_AVAILABILITY = gql`
    fragment ItemAvailability on ItemAvailability {
        invMastUid
        totalQuantity
        availability
        leadTimeDays
        leadTimeMessage
    }
`

export const FRAGMENT_ITEM_CUSTOMER_PART_NUMBER = gql`
    fragment ItemCustomerPartNumber on InvXRef {
        id
        invMastUid
        customerPartNumber
    }
`

export const FRAGMENT_ITEM_SOURCE_LOCATION = gql`
    fragment ItemSourceLocation on SourceLocation {
        invMastUid
        sourceLocId
        sourceLocName
        quantity
    }
`

export const FRAGMENT_ITEM_PRICE = gql`
    fragment ItemPrice on ItemPriceResponseGraphType {
        invMastUid
        quantity
        totalPrice
        unitPrice
        unitOfMeasure
        spaNumber
        spaCost
        spaMargin
        roundType
        unitSize
        spaType
        pricePage
    }
`

export const FRAGMENT_ITEM_DETAIL_FAMILIES = gql`
    fragment Families on ItemGraphType {
        itemFamilies {
            familyInvMastUid
            familyItemDescription
            familyKind
            members {
                invMastUid
                relationshipToFamily
                unitOfMeasure
                itemDescription
                relationshipQty
            }
        }
    }
`
