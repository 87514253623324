import React, { useContext, useEffect, useState } from 'react'
import { Formik, Form, Field, useFormikContext } from 'formik'
import FormikInput from '../../../_common/formik/input_v2'
import SelectField from '../../../_common/formik/select'
import Required from '../../../_common/required'
import { DivRow, FlexCol, FlexInline, FlexRow, HeaderActionButton, Layout } from '../../styledComponents'
import { addressSchema } from 'pageComponents/Checkout2/util/validationSchema'
import { useCheckout2 } from 'setup/CheckoutProviderFields'
import MyContext from 'setup/context'
import { ArrowDownwardSharp } from '@mui/icons-material'

const CopyFromContact = () => {
    const { setValues, values } = useFormikContext();
    const { userInfo } = useContext(MyContext);
    const { checkoutEditableFields, openEditors } = useCheckout2();

    function copyFromContact() {
        setValues({
            ...values,
            FirstName: checkoutEditableFields.SelectedContact_FirstName,
            LastName: checkoutEditableFields.SelectedContact_LastName,
            Phone: checkoutEditableFields.SelectedContact_Phone,
            PhoneExt: checkoutEditableFields.SelectedContact_PhoneExt
        }, true).then();
    }
    return (!!userInfo && userInfo.isImpersonatorUser &&
        <FlexRow>
            <HeaderActionButton onClick={copyFromContact} disabled={openEditors.contact}>From Contact <ArrowDownwardSharp /></HeaderActionButton>
        </FlexRow>
    );
}

export default function AddressInput(props) {
    const { checkoutInitializeData } = useCheckout2();
    const {
        address,
        addressType,
        hide,
        addressUpdatedCallback
    } = props;

    function save(values) {
        addressUpdatedCallback(values);
        hide();
    }

    const [disabled, setDisabled] = useState(true);

    //Dependent State/Province/Region Field Stuff
    const [selectedCountry, setSelectedCountry] = useState(address.Country);
    const [statesList, setStatesList] = useState((address.Country === 'US' ? checkoutInitializeData.UsStatesTerritories : (address.Country === 'CA' ? checkoutInitializeData.CaProvinces : []))
        .map((c) => { return { value: c.Value, label: c.Label } }));
    const [stateWord, setStateWord] = useState(address.Country === 'US' ? 'State' : (address.Country === 'CA' ? 'Province' : 'Region'));
    const [zipWord, setZipWord] = useState(address.Country === 'US' ? 'Zip Code' : 'Postal Code');
    const countries = checkoutInitializeData.Countries.map((c) => { return { value: c.Value, label: c.Label } });
    useEffect(() => {
        setStatesList((selectedCountry === 'US' ? checkoutInitializeData.UsStatesTerritories : (selectedCountry === 'CA' ? checkoutInitializeData.CaProvinces : []))
            .map((c) => { return { value: c.Value, label: c.Label } }));
        setStateWord(selectedCountry === 'US' ? 'State' : (selectedCountry === 'CA' ? 'Province' : 'Region'));
        setZipWord(selectedCountry === 'US' ? 'Zip Code' : 'Postal Code');
    }, [selectedCountry, checkoutInitializeData])

    function handleValidateFields(values) {
        //Pull out the current value of the country field into a local state, so it can affect other form elements
        if (values.Country !== selectedCountry) {
            setSelectedCountry(values.Country);
        }

        return addressSchema
            .isValid(values)
            .then((valid) => {
                setDisabled(!valid)
            })
    }

    return (<>
        <Formik
            initialValues={address}
            enableReinitialize={false}
            validationSchema={addressSchema}
            validate={handleValidateFields}
            validateOnBlur={true}
            validateOnChange={true}
            validateOnMount={true}
            onSubmit={(values) => save(values)}
        >
            {props => (<FlexCol>
                <CopyFromContact />
                <FlexRow>
                    <Form style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} onSubmit={props.handleSubmit}>
                        <FormikInput
                            label={<>Address Company/Nickname</>}
                            name="Name"
                        />
                        <FormikInput
                            label={<>First Name</>}
                            name="FirstName"
                        />
                        <FormikInput
                            label={<>Last Name</>}
                            name="LastName"
                        />
                        {/* <FormikInput 
                    label={<>Email<Required /></>}
                    name='Email'
                    /> */}
                        <Field
                            name="Country"
                            component={SelectField}
                            options={countries}
                            placeholder="Select a Country"
                            width={250}
                            isSearchable={false}
                            label={<>Country<Required /></>}
                        />
                        <FormikInput
                            label={<>Address<Required /></>}
                            name="Address1"
                        />
                        <FormikInput
                            label={<>Address Line 2</>}
                            name="Address2"
                        />
                        <FormikInput
                            label={<>City<Required /></>}
                            name="City"
                        />
                        <FlexInline>
                            {/*If there are states to choose from, show a picker. Otherwise, show a text box. Text box would be for international (non CA/US) addresses input by employee users */}
                            {statesList.length > 0 ? (
                                <Field
                                    name="State"
                                    component={SelectField}
                                    options={statesList}
                                    placeholder={`Select a ${stateWord}`}
                                    label={<>{stateWord}<Required /></>}
                                    width={200}
                                />) : (
                                <FormikInput
                                    label={<>{stateWord}<Required /></>}
                                    name="State"
                                    width={200}
                                    style={{ marginBottom: 7, width: 200 }}
                                />)}
                            <FormikInput
                                label={<>{zipWord}<Required /></>}
                                name="Zip"
                                width={200}
                                style={{ marginBottom: "7px", width: 200 }}
                            />

                        </FlexInline>
                        <FlexInline>
                            <FormikInput
                                label={<>Phone<Required /></>}
                                name="Phone"
                                width={300}
                                style={{ marginBottom: 7, width: 300 }}
                            />
                            <FormikInput
                                label={<>Extension</>}
                                name="PhoneExt"
                                width={100}
                                style={{ marginBottom: 7, width: 100 }}
                            />
                        </FlexInline>
                        <Layout>
                            {addressType === 'shipping' ?
                                <HeaderActionButton type="submit" disabled={disabled}>Save and Validate</HeaderActionButton>
                                :
                                <HeaderActionButton type="submit" disabled={disabled}>Save</HeaderActionButton>
                            }
                        </Layout>
                    </Form>
                </FlexRow>
            </FlexCol>)}
        </Formik>
    </>)
}