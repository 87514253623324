import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { GET_ROOT_CATEGORIES_HEADER } from 'setup/providerGQL'
import { useQuery } from '@apollo/client'

const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    // max-width: 1200px;
    width: 100%;
    align-items: center;
    justify-content: space-around;
    flex-direction: row;
    border: 1px solid lightgray;
    border-top: 0;
    box-shadow: 0px 3px 4px #dadada;
`
const ImgDiv = styled.div`
    margin-right: 10px;
    margin-bottom: 20px;
`
const Img = styled.img`
    max-width: 100px;
    width: 100%
`
const P = styled.p`
    font-size: 16px;
    &hover{
        font-weight: 600;
    }
`
const A = styled.a`
    display: flex;
    align-items: center;
    width: 245px;

`
const TextDiv = styled.div`

`
export default function ShopDropdownMenu() {
    const [categories, setCategories] = useState([])

    const [categoriesData, setCategoriesData] = useState(null);

    useQuery(GET_ROOT_CATEGORIES_HEADER, {
        onCompleted: data => {
            setCategories(data.getAllRootCategories)
        }
    })

    useEffect(() => {
        console.log(categories)
        setCategoriesData({
            label: 'Shop',
            to: '/categories',
            subItems: categories.map(({ name, urlSlug, imageUrl }) => ({
                image: imageUrl,
                label: name,
                to: `/categories/${urlSlug}/1`
            }))
        })
    }, [categories]);
    // useEffect(() => console.log(categoriesData), [categoriesData]);

    return<>
       
        <Container>
        {
            categoriesData?.subItems && categoriesData?.subItems?.map((item) => {
                return  (
                    <A key={item.to} href={item.to}>
                        <ImgDiv key={item.label}>
                            <Img src={item.image}/>
                        </ImgDiv>
                        <TextDiv>
                            <P>{item.label}</P>
                        </TextDiv>
                    </A>
                )
            }
            
            )

        }
        </Container>

    </>
}