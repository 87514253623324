import React, { useEffect, useState } from 'react'
import AddressBox from './AddressBox'
import { CheckBoxOutlineBlankRounded, CheckBoxRounded } from '@mui/icons-material'
import { HeaderActionButton, Layout, ScrollContainer, SelectedBorder, FlexChild, Grower } from '../../styledComponents'
import SearchIcon from '@mui/icons-material/Search';

/*Provides a pick list to select an address, and an option to add a new one */
export default function AddressSelect(props) {
    const {
        hide,
        title,
        addressList,
        addressSelectedCallback,
        newAddressCallback,
        currentSelectedAddressId
    } = props;
    const [filterText, setFilterText] = useState('');
    const [filteredAddresses, setFilteredAddresses] = useState(addressList);

    function addressSelected(addr) {
        addressSelectedCallback(addr);
        hide();
    }

    const addresses = filteredAddresses
        .sort(a => a.P21Id !== currentSelectedAddressId)
        .map((a, i) => (
            <FlexChild key={a.id} onClick={() => addressSelected(a)} >
                {currentSelectedAddressId === a.P21Id ? <CheckBoxRounded /> : <CheckBoxOutlineBlankRounded />}
                <Grower>
                    <SelectedBorder key={i} selected={currentSelectedAddressId === a.P21Id} >
                        <AddressBox address={a} />
                    </SelectedBorder>
                </Grower>
            </FlexChild>
        )
    );

    useEffect(() => {
        if (filterText.trim() === '') {
            setFilteredAddresses(addressList);
            return;
        }

        let _filterText = filterText.toLowerCase();

        setFilteredAddresses(
            addressList.filter((i) => {
                return (
                    [i.Address1, i.Address2, i.City, i.State, i.Zip, i.Country].join(' ').toLowerCase().includes(_filterText) ||
                    i.Name?.toLowerCase().includes(_filterText) ||
                    i.FirstName?.toLowerCase().includes(_filterText) ||
                    i.LastName?.toLowerCase().includes(_filterText) ||
                    i.Address1?.toLowerCase().includes(_filterText) ||
                    i.Address2?.toLowerCase().includes(_filterText) ||
                    i.City?.toLowerCase().includes(_filterText) ||
                    i.Country?.toLowerCase().includes(_filterText) ||
                    i.State?.toLowerCase().includes(_filterText) ||
                    i.Zip?.toLowerCase().includes(_filterText) ||
					String(i.P21Id)?.includes(_filterText)
                )
            })
        )
    }, [filterText]);

    return (
        <>
            <h4>{title}</h4>
            <h6>Tap to select an address to use for this order</h6>
            <Layout>
                <HeaderActionButton onClick={() => { hide(); newAddressCallback(); }}>New...</HeaderActionButton>
            </Layout>

            {addresses.length > 0 && <>
                <br />
                <form>
                    <SearchIcon aria-label='Search Address Book' />
                    <input type="text" value={filterText} onChange={(e) => setFilterText(e.target.value)} ></input>
                </form>
                <ScrollContainer>
                    {addresses}
                </ScrollContainer>
            </>}
        </>
    )
}