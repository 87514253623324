import React, { useContext, useState } from 'react'
import { getImagePath } from './helpers/generalHelperFunctions'
import AssemblyModal from './modals/AssemblyModal'
import Context from '../../setup/context'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

const Absolute = styled.div`
  position: absolute;
  color: #DB1633;
  bottom: 5px;
  right: 5px;
  font-size: 24px;
  background: white;
  line-height: 1;
  padding: 2px;
  cursor: ${props => props.pointer ? 'pointer' : 'auto'};
`

const AbsoluteTop = styled.div`
  position: absolute;
  color: #DB1633;
  top: 5px;
  width: 100%;
  font-size: ${props => props.textSize === 'small' ? '8px' : props.textSize === 'large' ? '16px' : '12px'};
`

const Img = styled.img`
  object-fit: contain;
  width: 100%;
  height: 100%; 
`

const Relative = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Center = styled.div`
  display: flex;
  justify-content: center;
  background: rgba(255,255,255,0.75);
`

const imageTypes = {
  original: "ORIGINAL",
  large: "LARGE",
  zoom: "ZOOM",
  thumbnail: "THUMB",
}

const imageOrder = [
  "THUMB",
  "LARGE",
  "ZOOM",
  "ORIGINAL",
]

export default function ItemImage(props) {
  const { details, size, textSize, postCart, link, lazy } = props
  const { userInfo } = useContext(Context)
  const [showModal, setShowModal] = useState(false)
  const { isNcnr, isAssembly, invMastUid, itemCode } = details

  const mediaItem = size === 'path' ? {} : findMediaItem(details.itemMedia || [], size)
  const src = getImagePath(size === 'path' ? details?.itemImageUrl || details?.imageUrl : mediaItem?.path)
  const alt = mediaItem?.altText

  function findMediaItem(media, size) {
    const mediaObj = {}
    for (const m of media) {
      mediaObj[m.itemMediaType] = m
    }
    const type = imageTypes[size.toLowerCase()]

    if (mediaObj[type]) {
      return mediaObj[type]
    } else {
      const orderIdx = imageOrder.findIndex(o => o === type)
      const newOrder = [...imageOrder.slice(orderIdx + 1), ...imageOrder.slice(0, orderIdx).reverse()]

      for (const o of newOrder) {
        if (mediaObj[o]) {
          return mediaObj[o]
        }
      }
    }
  }

  return (
    <Relative>
      {(isNcnr && postCart) && (
        <AbsoluteTop {...{ textSize }}>
          <Center>
            <span>
              NCNR Item
            </span>
          </Center>
        </AbsoluteTop>
      )}
      {link && <Link {...{
        to: link === 'self' ? src : link,
        ...(link === 'self' ? { target: 'blank' } : {})
      }}>
        <Img {...{
          src,
          alt,
          title: alt,
          ...(lazy ? { loading: 'lazy' } : {})
        }} />
      </Link>}
      {!link && <Img {...{
        src,
        alt,
        title: alt,
        ...(lazy ? { loading: 'lazy' } : {})
      }}
      />}
      {isAssembly && (
        <Absolute pointer={userInfo?.isAirlineEmployee} onClick={(e) => {
          if (userInfo?.isAirlineEmployee) {
            e.preventDefault()
            e.stopPropagation()
            setShowModal(true)
          }
        }}>
          <FontAwesomeIcon icon="wrench" />
        </Absolute>
      )}
      {showModal && (
        <AssemblyModal {...{
          showModal,
          setShowModal,
          invMastUid,
          itemCode
        }} />
      )}
    </Relative>
  )
}